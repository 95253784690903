//
// Notifications Drawer
// --------------------------------------------------


.drawer-pf {
  background-color: @color-pf-black-100;
  border: 1px solid @card-pf-border-color;
  .box-shadow(0 6px 12px fade(@color-pf-black, 17.5%));
  overflow-y: auto;
  position: absolute;
  right: 0;
  width: 320px;
  z-index: 2;
  .panel {
    border-bottom: none;
    border-left: none;
    border-right: none;
  }
  .panel-group .panel-heading + .panel-collapse .panel-body {
    border-top: none;
    border-bottom: 1px solid @card-pf-border-color;
    padding: 0;
  }
  .panel-counter {
    display: block;
    font-style: italic;
    line-height: 1.2;
    padding-left: 18px;
    padding-top: 5px;
  }
  .panel-heading { border-bottom: 1px solid @card-pf-border-color; }
  .panel-group {
    bottom: 0;
    margin-bottom: 0;
    position: absolute;
    top: 25px; // Height of Notifications Drawer box.
    width: 100%;
  }
  .panel-title a {
    cursor: pointer;
    display: block;
  }
  &.drawer-pf-expanded {
    @media (max-width: @screen-xs-max) {
      left:0;
      width: 100%;
    }
    @media (min-width: @screen-xs-max) {
      left: 270px;
    } // if the drawer is expanded and the screen is reduced this ensures it switches to full width on smaller screens.
    width: inherit;
    .drawer-pf-toggle-expand:before {
      content: "\f101";
    }
  }
  .blank-slate-pf {
    border: 0;
    margin-bottom: 0;
    padding: 30px;

    .blank-slate-pf-icon {
      font-size: 3em;
      line-height: 1em;
    }

    h1 {
      font-size: 1em;
      margin-bottom: 0;
      margin-top: 15px;
    }
  }
}
.drawer-pf-close, .drawer-pf-toggle-expand {
  color: @gray-darker;
  cursor: pointer;
  line-height: inherit;
  padding: 2px 10px;
  position: absolute;
  &:hover,
  &:focus {
    color: @color-pf-blue-400;
    text-decoration: none;
  }
}
.drawer-pf-toggle-expand {
  left: 0;
  &:before {
    content: "\f100";
    font-family: "FontAwesome";
  }
  &:hover {
    color: @link-color;
  }
}
.drawer-pf-close {
  right: 0;
}


.drawer-pf-action {
  display: flex;
  border-bottom: 1px solid @card-pf-border-color;

  .btn-link {
    color: @link-color;
    padding: 10px 0;
    .pficon, .fa, .fas, .far, .fab, .fal, {
      margin-right: 3px;
    }
    .pficon-close {
      position: relative;
      top: 1px;
    }

    &:hover { color: @link-hover-color; }
  }
}

.drawer-pf-action-link {
  border-left: solid 1px @color-pf-black-300;
  flex: 1 1 0%;
  margin: 10px 0;
  text-align: center;

  &:first-of-type {
    border-left-width: 0;
  }

  .btn-link {
    padding: 0;
  }
}

.drawer-pf-loading {
  color: @gray-pf;
  font-size: (@font-size-base + 2);
  padding: 20px 15px;
}

.drawer-pf-notification {
  border-bottom: 1px solid @card-pf-border-color;
  padding: 15px;
  .date {
    border-right: 1px solid #aaa;
    display: inline-block;
    line-height: 1;
    margin-right: 5px;
    padding-right: 9px;
  }
  > .dropdown-kebab-pf {
    margin-top: -3px;
  }

  .pficon {
    font-size: @font-size-large;
    margin-top: 3px;
  }
  &:last-of-type {
    border-bottom: none;
  }
  &:hover { background-color: @color-pf-blue-50; }
  &.unread .drawer-pf-notification-message {
    font-weight: bold;
    cursor: pointer;
  }
  &.expanded-notification {
    .date {
      border-right: none;
      padding-right: 0;
    }
  }
}

.drawer-pf-notification-content {
  cursor: default;
}

.drawer-pf-notification-info,
.drawer-pf-notification-message {
  display: block;
  padding-left: 27px;
  padding-right: 19px;
  .expanded-notification & {
    display: inline-block;
  }
}

.drawer-pf-title {
  background-color: @color-pf-black-100;
  border-bottom: 1px solid @card-pf-border-color;
  position: absolute;
  width: 100%;
  h3 {
    font-size: @font-size-base;
    margin: 0;
    padding: 6px 15px;
  }
}

.drawer-pf-notification.expanded-notification {
  .drawer-pf-notification-content {
    display: flex;
    @media (max-width: @screen-sm-max) {
      flex-direction: column;
    }
    .drawer-pf-notification-message {
      flex: 1 1;
    }
  }
}

/* Exclusive styles to work with Vertical Navigation */
.navbar-pf-vertical {
  .drawer-pf {
    height: ~"calc(100vh - @{drawer-pf-top-vertical} - 20px)";//to create a 20px offset bottom
    top: @drawer-pf-top-vertical; //menu height;
      @media (max-width: @screen-xs-max) {
        width:100%;
        height: calc(~"100vh - @{drawer-pf-top-vertical}");
      }

  }
  .nav .drawer-pf-trigger {
    .drawer-pf-trigger-icon {
      border-left: 1px solid @navbar-pf-navbar-utility-border-color;
      border-right: 1px solid @navbar-pf-navbar-utility-border-color;
      padding-left: 15px;
      padding-right: 15px;
    }
    &.open .drawer-pf-trigger-icon {
      background-color: @navbar-pf-navbar-utility-hover-bg-color;
    }
  }
}

/* Exclusive styles to work with Horizontal Navigation */
.navbar-pf {
  .drawer-pf {
    height: ~"calc(100vh - @{drawer-pf-top-horizontal} - 20px)";
    top: @drawer-pf-top-horizontal;
    border-top: 0;
    @media (max-width: @screen-xs-max) {
      width:100%;
      height: calc(~"100vh - @{drawer-pf-top-horizontal} - 32px");
      top:calc(~"@{drawer-pf-top-horizontal} + 10px");
    }
  }
  .drawer-pf-trigger-icon { cursor: pointer; }
}
