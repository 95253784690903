//
// Close icons
// --------------------------------------------------

//this should no longer be needed and be replaced with pficon-close. This is here for legacy. For further info see https://github.com/patternfly/patternfly/pull/781

.close {
  text-shadow: none;
  z-index: 1;
  position: relative;
  .opacity(.6);
  &:hover,
  &:focus {
    .opacity(.9);
  }
}
