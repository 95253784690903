//
// Bootstrap Tree View
// --------------------------------------------------

.treeview {
  .list-group {
    border-top: 0;
  }
  .list-group-item {
    background: transparent;
    border-bottom: 1px solid transparent !important;
    border-top: 1px solid transparent !important;
    cursor: default !important;
    margin-bottom: 0;
    overflow: hidden;
    padding: 0 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
    &:hover {
      background: none !important;
    }
    &.node-selected {
      background: none !important;
      border-color: transparent !important;
      color: inherit !important;
    }
    &.node-check-changed {
      span.node-icon,
      span.text {
        color: @bootstrap-treeview-highlight-color;
      }
    }
  }
  span.icon {
    display: inline-block;
    font-size: (@font-size-base + 1);
    min-width: 10px;
    text-align: center;
    > [class*="fa-angle"] {
      font-size: (@font-size-base + 3);
    }
    &.check-icon {
      margin-right: 10px;
    }
    &.expand-icon {
      cursor: pointer !important;
    }
  }
  span.image {
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
    height: 1.19em;
    line-height: 1em;
    margin-right: 5px;
    vertical-align: middle;
    width: 12px;
  }
  span.indent {
    margin-right: 5px;
  }
  .node-disabled {
    color: @color-pf-black-300;
    cursor: not-allowed;
    span.expand-icon {
      cursor: default !important;
    }
  }
  .node-hidden {
    display:none;
  }
}

.treeview-pf-hover .list-group-item {
  cursor: pointer !important;
  &:hover {
    background-color: @dropdown-link-hover-bg !important;
    border-color: @dropdown-link-hover-border-color !important;
  }
}

.treeview-pf-select .list-group-item {
  cursor: pointer !important;
  &.node-selected {
    background: @dropdown-link-active-bg !important;
    border-color: @dropdown-link-active-border-color !important;
    color: @dropdown-link-active-color !important;
  }
}
