/*
 *  Copyright 2022 Red Hat
 *
 *  Licensed under the Apache License, Version 2.0 (the "License");
 *  you may not use this file except in compliance with the License.
 *  You may obtain a copy of the License at
 *
 *      https://www.apache.org/licenses/LICENSE-2.0
 *
 *  Unless required by applicable law or agreed to in writing, software
 *  distributed under the License is distributed on an "AS IS" BASIS,
 *  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 *  See the License for the specific language governing permissions and
 *  limitations under the License.
 */
@loading-shadow: 3px;

.loading-container {
  position: absolute;
  left: 50%;
  top: 33%;
}

.loading {
  position: relative;
  left: -50%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  overflow: hidden;
  padding: 10px;
  width: 200px;
  height: 100px;
  background-color: @hal-almost-white;
  border: 1px solid lighten(@hal-dark-gray, 40%);
  border-radius: 2px;
  box-shadow: @loading-shadow 3px @loading-shadow @hal-light-gray,
  -@loading-shadow @loading-shadow @loading-shadow @hal-light-gray,
    @loading-shadow -@loading-shadow @loading-shadow @hal-light-gray,
    -@loading-shadow -@loading-shadow @loading-shadow @hal-light-gray;

  h3 {
    margin: 0;
    padding: 0;
  }
}
