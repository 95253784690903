//
// Forms
// --------------------------------------------------
.form-horizontal-pf-align-left {
  .control-label {
      text-align: left;
   }
  // In order to provide the proper support .pf-is-required is needed on left aligned labels to ensure accurate spacing when there is no required fields.
  &.required-pf {
    .control-label {
      @media (min-width: @screen-sm-min) {
        &:not(.required-pf) {
          padding-left: @form-horizontal-pf-align-left-padding-left;
        }
      }
    }
  }
}

.chars-remaining-pf span {
  font-weight: 600;
  padding-right: 5px;
}

.chars-warn-remaining-pf {
  color: @brand-danger;
}

.fields-status-pf {
  color: @color-pf-black-500;
  margin-bottom: 15px;
}

.form-control {
  &[disabled],
  &[readonly],
  fieldset[disabled] & {
    .box-shadow(none);
    color: @color-pf-black-500;
    &:hover {
      border-color: @input-border;
    }
  }
  &:hover {
    border-color: @input-border-hover;
    .has-error & {
      border-color: darken(@state-danger-text, 10%);
    }
    .has-success & {
      border-color: darken(@state-success-text, 10%);
    }
    .has-warning & {
      border-color: darken(@state-warning-text, 10%);
    }
  }
}

.has-error, .has-success, .has-warning {
  .checkbox,
  .checkbox-inline,
  .control-label,
  .radio,
  .radio-inline,
  &.checkbox label,
  &.checkbox-inline label,
  &.radio label,
  &.radio-inline label {
    color: @text-color;
  }
}

.help-block {
  margin-bottom: 0px;
}

.input-group .input-group-btn .btn {
  .box-shadow(none);
}

label {
  font-weight: 600;
  &.required-pf:before {
    color: @brand-danger;
    content: "*";
    margin-right: 3px;
  }
}

span.required-pf {
  color: @brand-danger;
}

.fields-section-pf {
  border-color: @color-pf-black-200;
  border-style: solid;
  border-width: 1px 0 0;
  margin-top: 25px;
  padding: 15px 0 0;
}
.fields-section-header-pf {
  border: none;
  font-size: @font-size-base;
  margin: 0;
  padding-right: @padding-large-horizontal;
  width: auto;
  .fa-angle-right {
    cursor: pointer;
    font-size: @font-size-large;
    width: @font-size-large;
  }
}

.form-control-pf-editable {
  display: flex;
  &.form-control-pf-full-width {
    display: block;
    > .form-control-pf-value {
      text-align: left;
    }
    .action-buttons {
      float: right;
    }
  }
  &.form-control-pf-edit {
    > .form-control-pf-value {
      display: none;
    }
    > :not(.form-control-pf-value):not(.spinner),
    > .bootstrap-select.btn-group:not(.input-group-btn) {
      display: inline-block;
    }
  }
  &.form-control-pf-updating {
    > .spinner {
      display: inline-block;
    }
    > :not(.spinner) {
      display: none;
    }
  }
  > .form-control-pf-value {
    background: none;
    border: 1px solid transparent;
    display: inline-block;
    padding: @padding-base-vertical @padding-base-horizontal;
    &:focus {
      border-color: @color-pf-black-400;
    }
    &:hover {
      border-color: @color-pf-black-400;
      cursor: pointer;
    }
    + :not(textarea) {
      flex: 1;
    }
    i {
      margin-left: @padding-base-horizontal;
    }
  }
  > :not(.form-control-pf-value) {
    display: none;
  }
  > .form-control-pf-textbox {
    position: relative;
    input {
      padding-right: @input-height-base;
    }
    .form-control-pf-empty {
      background: none;
      border: none;
      color: @color-pf-black-400;
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
    }
  }
  > .btn {
    margin-left: 3px;
  }
  > .spinner {
    margin: ((@input-height-base - (@font-size-base * 1.5)) * .5) 0;
  }
  > .bootstrap-select.btn-group:not(.input-group-btn) {
    display: none;
  }
  > textarea {
    display: block;
    min-height: 84px;
    resize: vertical;
  }
}
