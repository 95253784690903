/*
 *  Copyright 2022 Red Hat
 *
 *  Licensed under the Apache License, Version 2.0 (the "License");
 *  you may not use this file except in compliance with the License.
 *  You may obtain a copy of the License at
 *
 *      https://www.apache.org/licenses/LICENSE-2.0
 *
 *  Unless required by applicable law or agreed to in writing, software
 *  distributed under the License is distributed on an "AS IS" BASIS,
 *  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 *  See the License for the specific language governing permissions and
 *  limitations under the License.
 */
.navbar-footer {
  height: @hal-footer-height;
  min-height: @hal-footer-height;
  background-color: @hal-almost-white;
  border-top: 1px solid @hal-gray;

  .footer-progress {
    margin: 10px 0 0 20px;
    width: 250px;
  }

  .dropdown {
    float: left;
  }

  .caret::before {
    content: "\f106";
  }

  .footer-tools {
    float: right;
    border-bottom: 0;
    font-size: 11px;
    margin: 0;

    > li {
      float: left;
      > .tool {
        border-left: 1px solid @hal-gray;
        line-height: 1;
        padding: 7px 10px;

        > .fa, .pficon {
          padding-right: 4px;
        }

        > .fa.recording {
          color: #cc0000;
          margin-left: 4px;
          padding: 0;
        }
      }
    }

    .open > a, .open > a:hover, .open > a:focus {
      border-left: 1px solid @hal-gray;
    }
    .open .dropdown-menu {
      left: auto;
      right: 0;
    }
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
}
