//
// Icons
// --------------------------------------------------
// Custom icons and selections from IcoMoon - Free (http://icomoon.io/#icons)

@font-face {
  font-family: "@{icon-font-name-pf}";
  src:url("@{font-path}/@{icon-font-name-pf}.eot");
  src:url("@{font-path}/@{icon-font-name-pf}.eot?#iefix") format("embedded-opentype"),
    url("@{font-path}/@{icon-font-name-pf}.ttf") format("truetype"),
    url("@{font-path}/@{icon-font-name-pf}.woff") format("woff"),
    url("@{font-path}/@{icon-font-name-pf}.svg#@{icon-font-name-pf}") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="@{icon-prefix}-"],
[class*=" @{icon-prefix}-"] {
  display: inline-block;
  font-family: "@{icon-font-name-pf}";
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  speak: none;
  text-transform: none;
  /* Better Font Rendering =========== */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}
.@{icon-prefix}-add-circle-o:before {
  content: @pficon-var-add-circle-o;
}
.@{icon-prefix}-applications:before {
  content: @pficon-var-applications;
}
.@{icon-prefix}-arrow:before {
  content: @pficon-var-arrow;
}
.@{icon-prefix}-asleep:before {
  content: @pficon-var-asleep;
}
.@{icon-prefix}-automation:before {
  content: @pficon-var-automation;
}
.@{icon-prefix}-build:before {
  content: @pficon-var-build;
}
.@{icon-prefix}-builder-image:before {
  content: @pficon-var-builder-image;
}
.@{icon-prefix}-bundle:before {
  content: @pficon-var-bundle;
}
.@{icon-prefix}-blueprint:before {
  content: @pficon-var-blueprint;
}
.@{icon-prefix}-catalog:before {
  content: @pficon-var-catalog;
}
.@{icon-prefix}-chat:before {
  content: @pficon-var-chat;
}
.@{icon-prefix}-close:before {
  content: @pficon-var-close;
}
.@{icon-prefix}-cloud-security:before {
  content: @pficon-var-cloud-security;
}
.@{icon-prefix}-cloud-tenant:before {
  content: @pficon-var-cloud-tenant;
}
.@{icon-prefix}-cluster:before {
  content: @pficon-var-cluster;
}
.@{icon-prefix}-connected:before {
  content: @pficon-var-connected;
}
.@{icon-prefix}-container-node:before {
  content: @pficon-var-container-node;
}
.@{icon-prefix}-cpu:before {
  content: @pficon-var-cpu;
}
.@{icon-prefix}-degraded:before {
  content: @pficon-var-degraded;
}
.@{icon-prefix}-delete:before {
  content: @pficon-var-delete;
}
.@{icon-prefix}-disconnected:before {
  content: @pficon-var-disconnected;
}
.@{icon-prefix}-domain:before {
  content: @pficon-var-domain;
}
.@{icon-prefix}-drag-drop:before {
  content: @pficon-var-drag-drop;
}
.@{icon-prefix}-edit:before {
  content: @pficon-var-edit;
}
.@{icon-prefix}-enhancement:before {
  content: @pficon-var-enhancement;
}
.@{icon-prefix}-enterprise:before {
  content: @pficon-var-enterprise;
}
.@{icon-prefix}-equalizer:before {
  content: @pficon-var-equalizer;
}
.@{icon-prefix}-error-circle-o:before {
  color: @brand-danger;
  content: @pficon-var-error-circle-o;
}
.@{icon-prefix}-export:before {
  content: @pficon-var-export;
}
.@{icon-prefix}-flag:before,
.@{icon-prefix}-messages:before { // class name deprecated
  content: @pficon-var-flag;
}
.@{icon-prefix}-flavor:before {
  content: @pficon-var-flavor;
}
.@{icon-prefix}-filter:before {
  content: @pficon-var-filter;
}
.@{icon-prefix}-folder-close:before {
  content: @pficon-var-folder-close;
}
.@{icon-prefix}-folder-open:before {
  content: @pficon-var-folder-open;
}
.@{icon-prefix}-help:before {
  content: @pficon-var-help;
}
.@{icon-prefix}-history:before {
  content: @pficon-var-history;
}
.@{icon-prefix}-home:before {
  content: @pficon-var-home;
}
.@{icon-prefix}-image:before {
  content: @pficon-var-image;
}
.@{icon-prefix}-import:before {
  content: @pficon-var-import;
}
.@{icon-prefix}-in-progress:before {
  content: @pficon-var-in-progress;
}
.@{icon-prefix}-info:before {
  content: @pficon-var-info;
}
.@{icon-prefix}-infrastructure:before {
  content: @pficon-var-infrastructure;
}
.@{icon-prefix}-integration:before {
  content: @pficon-var-integration;
}
.@{icon-prefix}-key:before {
  content: @pficon-var-key;
}
.@{icon-prefix}-locked:before {
  content: @pficon-var-locked;
}
.@{icon-prefix}-maintenance:before {
  content: @pficon-var-maintenance;
}
.@{icon-prefix}-memory:before {
  content: @pficon-var-memory;
}
.@{icon-prefix}-middleware:before {
  content: @pficon-var-middleware;
}
.@{icon-prefix}-migration:before {
  content: @pficon-var-migration;
}
.@{icon-prefix}-monitoring:before {
  content: @pficon-var-monitoring;
}
.@{icon-prefix}-network:before {
  content: @pficon-var-network;
}
.@{icon-prefix}-network-range:before {
  content: @pficon-var-network-range;
}
.@{icon-prefix}-on:before {
  content: @pficon-var-on;
}
.@{icon-prefix}-on-running:before {
  content: @pficon-var-on-running;
}
.@{icon-prefix}-optimize:before {
  content: @pficon-var-optimize;
}
.@{icon-prefix}-orders:before {
  content: @pficon-var-orders;
}
.@{icon-prefix}-off:before {
  content: @pficon-var-off;
}
.@{icon-prefix}-ok:before {
  color: @brand-success;
  content: @pficon-var-ok;
}
.@{icon-prefix}-paused:before {
  content: @pficon-var-paused;
}
.@{icon-prefix}-pending:before {
  content: @pficon-var-pending;
}
.@{icon-prefix}-plugged:before {
  content: @pficon-var-plugged;
}
.@{icon-prefix}-port:before {
  content: @pficon-var-port;
}
.@{icon-prefix}-print:before {
  content: @pficon-var-print;
}
.@{icon-prefix}-process-automation:before {
  content: @pficon-var-process-automation;
}
.@{icon-prefix}-private:before {
  content: @pficon-var-private;
}
.@{icon-prefix}-project:before {
  content: @pficon-var-project;
}
.@{icon-prefix}-rebalance:before {
  content: @pficon-var-rebalance;
}
.@{icon-prefix}-rebooting:before {
  content: @pficon-var-rebooting;
}
.@{icon-prefix}-refresh:before, // class name deprecated
.@{icon-prefix}-restart:before {
  content: @pficon-var-restart;
}
.@{icon-prefix}-regions:before {
  content: @pficon-var-regions;
}
.@{icon-prefix}-registry:before {
  content: @pficon-var-registry;
}
.@{icon-prefix}-remove:before {
  content: @pficon-var-remove;
}
.@{icon-prefix}-replicator:before {
  content: @pficon-var-replicator;
}
.@{icon-prefix}-repository:before {
  content: @pficon-var-repository;
}
.@{icon-prefix}-resource-pool:before {
  content: @pficon-var-resource-pool;
}
.@{icon-prefix}-resources-almost-empty:before {
  content: @pficon-var-resources-almost-empty;
}
.@{icon-prefix}-resources-almost-full:before {
  content: @pficon-var-resources-almost-full;
}
.@{icon-prefix}-resources-full:before {
  content: @pficon-var-resources-full;
}
.@{icon-prefix}-route:before {
  content: @pficon-var-route;
}
.@{icon-prefix}-running:before {
  content: @pficon-var-running;
}
.@{icon-prefix}-satellite:before {
  content: @pficon-var-satellite;
}
.@{icon-prefix}-save:before {
  content: @pficon-var-save;
}
.@{icon-prefix}-screen:before {
  content: @pficon-var-screen;
}
.@{icon-prefix}-search:before {
  content: @pficon-var-search;
}
.@{icon-prefix}-security:before {
  content: @pficon-var-security;
}
.@{icon-prefix}-server:before {
  content: @pficon-var-server;
}
.@{icon-prefix}-server-group:before {
  content: @pficon-var-server-group;
}
.@{icon-prefix}-service:before {
  content: @pficon-var-service;
}
.@{icon-prefix}-services:before {
  content: @pficon-var-services;
}
.@{icon-prefix}-service-catalog:before {
  content: @pficon-var-service-catalog;
}
.@{icon-prefix}-settings:before {
  content: @pficon-var-settings;
}
.@{icon-prefix}-sort-common-asc:before {
  content: @pficon-var-sort-common-asc;
}
.@{icon-prefix}-sort-common-desc:before {
  content: @pficon-var-sort-common-desc;
}
.@{icon-prefix}-spinner:before {
  content: @pficon-var-spinner;
}
.@{icon-prefix}-spinner2:before {
  content: @pficon-var-spinner2;
}
.@{icon-prefix}-storage-domain:before {
  content: @pficon-var-storage-domain;
}
.@{icon-prefix}-template:before {
  content: @pficon-var-template;
}
.@{icon-prefix}-tenant:before {
  content: @pficon-var-tenant;
}
.@{icon-prefix}-thumb-tack-o:before {
  content: @pficon-var-thumb-tack-o;
}
.@{icon-prefix}-topology:before {
  content: @pficon-var-topology;
}
.@{icon-prefix}-trend-down:before {
  content: @pficon-var-trend-down;
}
.@{icon-prefix}-trend-up:before {
  content: @pficon-var-trend-up;
}
.@{icon-prefix}-unknown:before {
  content: @pficon-var-unknown;
}
.@{icon-prefix}-user:before {
  content: @pficon-var-user;
}
.@{icon-prefix}-users:before {
  content: @pficon-var-users;
}
.@{icon-prefix}-unlocked:before {
  content: @pficon-var-unlocked;
}
.@{icon-prefix}-unplugged:before {
  content: @pficon-var-unplugged;
}
.@{icon-prefix}-vcenter:before {
  content: @pficon-var-vcenter;
}
.@{icon-prefix}-virtual-machine:before {
  content: @pficon-var-virtual-machine;
}
.@{icon-prefix}-volume:before {
  content: @pficon-var-volume;
}
.@{icon-prefix}-warning-triangle-o:before {
  color: @brand-warning;
  content: @pficon-var-warning-triangle-o;
}
.@{icon-prefix}-zone:before {
  content: @pficon-var-zone;
}
