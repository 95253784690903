//
// Buttons
// --------------------------------------------------

.btn {
  .box-shadow(0 2px 3px fade(@color-pf-black, 10%));
  &:active {
    .box-shadow(inset 0 2px 8px fade(@color-pf-black, 20%));
  }
  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    background-color: @color-pf-black-100 !important;
    background-image: none !important;
    border-color: @color-pf-black-300 !important;
    color: @color-pf-black-500 !important;
    opacity: 1;
    &:active {
      .box-shadow(none);
    }
    &.btn-link {
      background-color: transparent !important;
      border: 0;
    }
  }
}

.btn-danger {
  .button-variant(@btn-danger-color; @btn-danger-bg; @btn-danger-bg-img-start; @btn-danger-bg-img-stop; @btn-danger-border);
}

.btn-default {
  .button-variant(@btn-default-color; @btn-default-bg; @btn-default-bg-img-start; @btn-default-bg-img-stop; @btn-default-border);
}

.btn-link {
  &,
  &:active {
    .box-shadow(none);
  }
}

.btn-primary {
  .button-variant(@btn-primary-color; @btn-primary-bg; @btn-primary-bg-img-start; @btn-primary-bg-img-stop; @btn-primary-border);
}

.btn-xs,
.btn-group-xs .btn {
  font-weight: @btn-xs-font-weight;
}
