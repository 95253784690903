//
// Modals
// --------------------------------------------------

// Modal header
// Top section of the modal w/ title and dismiss
.modal-header {
  background-color: @color-pf-black-150;
  border-bottom: none;
  padding: @modal-title-padding-vertical @modal-title-padding-horizontal;
}
// Close icon
.modal-header .close {
  margin-top: 2px;
}

// Title text within header
.modal-title {
  font-size: 13px;
  font-weight: 700;
}

// Footer (for actions)
.modal-footer {
  border-top: none;
  margin-top: 15px;
  padding: (@modal-inner-padding - 1) @modal-inner-padding @modal-inner-padding;
  > .btn {
    padding-left: 10px;
    padding-right: 10px;
    > .fa-angle-left {
      margin-right: 5px;
    }
    > .fa-angle-right {
      margin-left: 5px;
    }
  }
}

// message-dialog pattern
.message-dialog-pf .modal-body {
  display: flex;

  .fa, .fas, .far, .fab, .fal,
  .pficon {
    font-size: 30px;
    margin-right: 15px;
  }
}
