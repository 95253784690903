//
// Tooltips
// --------------------------------------------------

// Base class
.tooltip {
  font-size: @tooltip-font-size;
  line-height: 1.4;
}

// Wrapper for the tooltip content
.tooltip-inner {
  padding: 7px 12px;
  text-align: left;
}
