/*
 *  Copyright 2022 Red Hat
 *
 *  Licensed under the Apache License, Version 2.0 (the "License");
 *  you may not use this file except in compliance with the License.
 *  You may obtain a copy of the License at
 *
 *      https://www.apache.org/licenses/LICENSE-2.0
 *
 *  Unless required by applicable law or agreed to in writing, software
 *  distributed under the License is distributed on an "AS IS" BASIS,
 *  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 *  See the License for the specific language governing permissions and
 *  limitations under the License.
 */
.editor-controls {
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.editor-status {
  align-self: center;
  flex: 2;
  min-width: 50px;
  .text-overflow();
}

pre.editor {
  margin: 0;
  padding: 0;
}