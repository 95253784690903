/*
 *  Copyright 2022 Red Hat
 *
 *  Licensed under the Apache License, Version 2.0 (the "License");
 *  you may not use this file except in compliance with the License.
 *  You may obtain a copy of the License at
 *
 *      https://www.apache.org/licenses/LICENSE-2.0
 *
 *  Unless required by applicable law or agreed to in writing, software
 *  distributed under the License is distributed on an "AS IS" BASIS,
 *  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 *  See the License for the specific language governing permissions and
 *  limitations under the License.
 */
.alert {

  &.alert-suspended {
    background-color: #def3ff;
    border-color: #00659c;
    color: #363636;
  }

  > .fa {
    font-size: 22px;
    position: absolute;
    left: 13px;
    top: 10px;
  }
}

.fa.grey:before {
  color: #666;
}

.fa.blue:before {
  color: #004368;
}

.pficon-disabled-circle-o:before {
  color: #666;
  content: "\e61d";
}

.item-icon {
  font-size: 14px;
  margin: 1px 10px 0 0;
}
