/*
 *  Copyright 2022 Red Hat
 *
 *  Licensed under the Apache License, Version 2.0 (the "License");
 *  you may not use this file except in compliance with the License.
 *  You may obtain a copy of the License at
 *
 *      https://www.apache.org/licenses/LICENSE-2.0
 *
 *  Unless required by applicable law or agreed to in writing, software
 *  distributed under the License is distributed on an "AS IS" BASIS,
 *  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 *  See the License for the specific language governing permissions and
 *  limitations under the License.
 */
.log-file-follow {
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  margin-right: 20px;

  label {
    font-weight: normal;
    margin-bottom: 0;
    margin-right: 10px;
  }
}

.log-file-editor-container {

  .spinner {
    display: none;
    position: relative;
  }

  &.log-file-loading {
    #log-file-editor {
      opacity: .3;
    }

    .spinner {
      display: block;
    }
  }
}

#log-file-editor {
  font-size: 11px;
}

.log-file-preview {
  min-height: 10rem;
}