/*
 *  Copyright 2022 Red Hat
 *
 *  Licensed under the Apache License, Version 2.0 (the "License");
 *  you may not use this file except in compliance with the License.
 *  You may obtain a copy of the License at
 *
 *      https://www.apache.org/licenses/LICENSE-2.0
 *
 *  Unless required by applicable law or agreed to in writing, software
 *  distributed under the License is distributed on an "AS IS" BASIS,
 *  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 *  See the License for the specific language governing permissions and
 *  limitations under the License.
 */
.modal-md {

  .hal-form-label {
    .make-lg-column(@hal-dialog-md-label-columns);
    .make-md-column(@hal-dialog-md-label-columns);
    .make-sm-column(@hal-dialog-md-label-columns);
  }

  .hal-form-input {
    .make-lg-column(@hal-dialog-md-input-columns);
    .make-md-column(@hal-dialog-md-input-columns);
    .make-sm-column(@hal-dialog-md-input-columns);
  }

  .hal-form-offset {
    .make-lg-column(@hal-dialog-md-input-columns);
    .make-md-column(@hal-dialog-md-input-columns);
    .make-sm-column(@hal-dialog-md-input-columns);
    .make-lg-column-offset(@hal-dialog-md-label-columns);
    .make-md-column-offset(@hal-dialog-md-label-columns);
    .make-sm-column-offset(@hal-dialog-md-label-columns);
  }
}

.modal-mx {
  width: 98%;
}

.modal-body {

  .alert ul {
    margin-bottom: 0;
  }

  > .form-section {
    margin-top: 0;
  }

  .form {
    background-color: white;
  }
  .form-buttons {
    /*
     * Do not show the form buttons inside a dialog.
     * The form's save() & cancel() methods must be called manually from the dialog's button handlers.
     */
    display: none;
  }
}

.message-details {
  .timestamp {
    color: #8b8d8f;
  }
}

.message-details-pre {
  white-space: pre;
}

.product-versions-pf dt {
  font-weight: 600;
}