//
// Popovers
// --------------------------------------------------

.popover {
  .box-shadow(0 2px 2px fade(@color-pf-black, 8%));
  padding: 0;
  &.bottom {
    .popover-title:before {
      content:"";
      position: absolute;
      top: @popover-position-top;
      left: 50%;
      transform: translateX(-50%);
      border-top-width: @popover-border-top-width;
      border-width: @popover-border-width;
      border-color: transparent;
      border-bottom-color: @popover-border-bottom-color;
      border-style: solid;
    }
  }
}

.popover-content {
  color: @gray-pf;
  line-height: 18px;
  padding: 10px 14px;
}

.popover-title {
  border-bottom: none;
  border-radius: 0;
  color: @gray-pf;
  font-size: (@font-size-base + 1);
  font-weight: 700;
  min-height: 34px;
  .close {
    height: 22px;
    position: absolute;
    right: 8px;
    top: 6px;
  }
  &.closable {
    padding-right: 30px;
  }
}
.popover-pf-info {
  color: @color-pf-black-900;
    &:hover, &:active {
      color : @color-pf-blue-500;
    }
}
