/*
 *  Copyright 2022 Red Hat
 *
 *  Licensed under the Apache License, Version 2.0 (the "License");
 *  you may not use this file except in compliance with the License.
 *  You may obtain a copy of the License at
 *
 *      https://www.apache.org/licenses/LICENSE-2.0
 *
 *  Unless required by applicable law or agreed to in writing, software
 *  distributed under the License is distributed on an "AS IS" BASIS,
 *  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 *  See the License for the specific language governing permissions and
 *  limitations under the License.
 */
.popover-content {
  .tour-buttons {
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
    .btn {
      font-weight: normal !important;
    }
    #tour-button-back {
      margin-right: 5px;
    }
  }
}

.current-step {
  color: #0099d3;
}

.eap-quick-tour {
  width: 1120px;
  height: 800px;
  position: relative;
  margin: 0 auto;
  h1 {
    text-align: center;
  }
}

.eap-pagination {
  text-align: center;
  position: absolute;
  bottom: 20px;
  left: 40px;
  right: 40px;
  margin: 0;
}

.eap-quick-tour-description {
  text-align: center;
  p {
    margin: 0 220px 20px 220px;
  }
  img {
    display: block;
    margin: 40px auto 40px auto;
  }
}

.eap-quick-tour-steps {
  position: relative;
  img {
    position: absolute;
  }
  p {
    position: absolute;
  }
}

.domain {
  // Step 1 ===============================
  &&.eap-quick-tour-step-1 {
    img {
      right: 50px;
      top: 80px;
    }
    p {
      &.text-1 {
        top: 10px;
        right: 70px;
        width: 330px;
      }
      &.text-2 {
        top: 195px;
        left: 20px;
        width: 205px;
      }
      &.text-3 {
        top: 335px;
        left: 20px;
        width: 205px;
      }
    }
  }

  // Step 6 ===============================
  &&.eap-quick-tour-step-6 {
    img {
      left: 165px;
      top: 80px;
    }
    p {
      &.text-1 {
        text-align: right;
        top: 0;
        left: 90px;
        width: 330px;
      }
      &.text-2 {
        top: 0;
        right: 180px;
        width: 400px;
      }
    }
  }

  // Step 7 ===============================
  &&.eap-quick-tour-step-7 {
    img {
      left: 165px;
      top: 70px;
    }
    p {
      &.text-1 {
        text-align: right;
        top: 20px;
        left: 140px;
        width: 300px;
      }
      &.text-2 {
        top: 20px;
        right: 240px;
        width: 400px;
      }
      &.text-3 {
        top: 65px;
        right: 200px;
        width: 400px;
      }
    }
  }

  // Step 8 ===============================
  &&.eap-quick-tour-step-8 {
    img {
      right: 50px;
      top: 70px;
    }
    p {
      &.text-1 {
        text-align: right;
        top: 175px;
        left: 20px;
        width: 220px;
      }
      &.text-2 {
        text-align: right;
        top: 10px;
        left: 450px;
        width: 250px;
      }
      &.text-3 {
        top: 10px;
        right: 180px;
        width: 200px;
      }
      &.text-4 {
        top: 70px;
        right: 30px;
        width: 300px;
      }
    }
  }

  // Step 9 ===============================
  &&.eap-quick-tour-step-9 {
    img {
      right: 50px;
      top: 70px;
    }
    p {
      &.text-1 {
        text-align: right;
        top: 130px;
        left: 20px;
        width: 230px;
      }
      &.text-2 {
        top: 0;
        right: 50px;
        width: 470px;
      }
    }
  }

  // Step 10 ===============================
  &&.eap-quick-tour-step-10 {
    img {
      right: 50px;
      top: 70px;
    }
    p {
      &.text-1 {
        text-align: right;
        top: 165px;
        left: 20px;
        width: 220px;
      }
      &.text-2 {
        text-align: right;
        top: -20px;
        left: 30px;
        width: 550px;
      }
      &.text-3 {
        top: 20px;
        right: 120px;
        width: 220px;
      }
    }
  }
}

.standalone {
  // Step 1 ===============================
  &&.eap-quick-tour-step-1 {
    img {
      right: 50px;
      top: 80px;
    }
    p {
      &.text-1 {
        top: 10px;
        right: 70px;
        width: 330px;
      }
      &.text-2 {
        top: 195px;
        left: 70px;
        width: 205px;
      }
      &.text-3 {
        top: 345px;
        left: 20px;
        width: 205px;
      }
    }
  }

  // Step 2 ===============================
  &&.eap-quick-tour-step-2 {
    img {
      left: 165px;
      top: 80px;
    }
    p {
      &.text-1 {
        top: 0;
        left: 386px;
        width: 400px;
      }
    }
  }

  // Step 3 ===============================
  &&.eap-quick-tour-step-3 {
    img {
      top: 70px;
      left: 150px;
    }
    p {
      &.text-1 {
        text-align: right;
        top: 0;
        left: 120px;
        width: 230px;
      }
      &.text-2 {
        top: 0;
        right: 70px;
        width: 470px;
      }
    }
  }

  // Step 4 ===============================
  &&.eap-quick-tour-step-4 {
    img {
      top: 70px;
      left: 150px;
    }
    p {
      &.text-1 {
        top: 10px;
        left: 350px;
        width: 300px;
      }
      &.text-2 {
        top: 60px;
        left: 520px;
        width: 300px;
      }
    }
  }

  // Step 5 ===============================
  &&.eap-quick-tour-step-5 {
    img {
      right: 50px;
      top: 70px;
    }
    p {
      &.text-1 {
        text-align: right;
        top: 165px;
        left: 20px;
        width: 220px;
      }
      &.text-2 {
        text-align: right;
        top: -20px;
        left: 30px;
        width: 550px;
      }
      &.text-3 {
        top: 20px;
        right: 120px;
        width: 220px;
      }
    }
  }
}
