/*
 *  Copyright 2022 Red Hat
 *
 *  Licensed under the Apache License, Version 2.0 (the "License");
 *  you may not use this file except in compliance with the License.
 *  You may obtain a copy of the License at
 *
 *      https://www.apache.org/licenses/LICENSE-2.0
 *
 *  Unless required by applicable law or agreed to in writing, software
 *  distributed under the License is distributed on an "AS IS" BASIS,
 *  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 *  See the License for the specific language governing permissions and
 *  limitations under the License.
 */
.wizard-hal-no-sidebar {
  margin-left: 0;
}

.wizard-pf-main .blank-slate-pf {
  padding: 40px 80px;
}

.wizard-pf-error-icon {
  color: @color-pf-red-300;
  font-size: (@font-size-base * 5.6);
  line-height: (@font-size-base * 5.6);
}

.wizard-hal-error-text {
  text-align: left;
  white-space: pre;
}

.wizard-pf-step-title {
  .text-overflow();
}

.wizard-pf-step {
    line-height: 19px !important;
}