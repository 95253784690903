/*
 *  Copyright 2022 Red Hat
 *
 *  Licensed under the Apache License, Version 2.0 (the "License");
 *  you may not use this file except in compliance with the License.
 *  You may obtain a copy of the License at
 *
 *      https://www.apache.org/licenses/LICENSE-2.0
 *
 *  Unless required by applicable law or agreed to in writing, software
 *  distributed under the License is distributed on an "AS IS" BASIS,
 *  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 *  See the License for the specific language governing permissions and
 *  limitations under the License.
 */
.list-pf-title {
  h3 {
    margin-top: 0;
    margin-bottom: 5px;
  }
}

.list-pf-title, .list-pf-description, .list-pf-additional-content {
  p {
    margin: 0;
  }
}

.list-pf-header {
  > a {
    cursor: pointer;
    display: block;
    color: inherit;
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
    &:focus {
      outline: none;
    }
  }
}

.list-pf-container-long {
  border-color: @list-pf-border-color;
  border-left-color: @color-pf-white;
  border-right-color: @color-pf-white;
  border-style: solid;
  border-width: 1px;
  border-bottom: none;
  display: none;
  &:hover {
    background-color: @list-pf-hover-background-color;
  }
  &.active {
    background-color: @list-pf-header-background-color;
    border-color: @list-pf-active-border-color;
    border-bottom-width: 1px;
    border-bottom-style: solid;
  }
}

.list-hal-actions {
  justify-content: flex-end;
  min-width: 50px;
}

.list-hal-additional-content {
  justify-content: start;
}

.list-hal-main-content, .list-hal-additional-content {
}

.list-hal-icon-big {
  background-color: #f5f5f5;
  border-radius: 50%;
  font-size: 2em;
  height: 50px;
  line-height: 50px;
  text-align: center;
  width: 50px;
}

.list-hal-icon-error {
  background-color: #ffe6e6;
  color: @color-pf-red-100;
}

.list-hal-icon-info {
  color: @color-pf-black-500;
}

.list-hal-icon-progress {
  background-color: #def3ff;
  color: @color-pf-blue-600;
}

.list-hal-icon-success {
  background-color: #e9f4e9;
  color: @color-pf-green-400;
}

.list-hal-icon-warning {
  background-color: #fdf2e5;
  color: @color-pf-orange-400;
}

.list-hal-selected {
  line-height: 40px;
  text-align: right;
}
