//
// Typography
// --------------------------------------------------

h1,
.h1,
h2,
.h2 {
  font-weight: 300;
}

.page-header .actions {
  margin-top: 8px;
  a > .pficon {
    margin-right: 4px;
  }
}

@media (min-width: @grid-float-breakpoint-max) {
  .page-header-bleed-left {
    margin-left: (-(@grid-gutter-width / 2));
  }
  .page-header-bleed-right {
    margin-right: ((-@grid-gutter-width / 2));
    .actions {
      margin-right: (@grid-gutter-width / 2);
    }
  }
}
