//
// Progress Bar
// --------------------------------------------------

@keyframes progress-bar-stripes {
  from  { background-position: 0 0; }
  to    { background-position: 40px 0; }
}

.progress {
  .box-shadow(inset 0 0 1px fade(@color-pf-black, 25%));
  &.progress-label-left,
  &.progress-label-top-right {
    overflow: visible;
    position: relative;
  }
  &.progress-label-left {
    margin-left: 40px;
  }
  &.progress-sm {
    height: @progress-sm;
    margin-bottom: @progress-sm;
  }
  &.progress-xs {
    height: @progress-xs;
    margin-bottom: @progress-xs;
  }
  td > &:first-child:last-child {
    margin-bottom: 0;
    margin-top: 3px;
  }
}

.progress-bar {
  box-shadow: none;
  .progress-label-left & span,
  .progress-label-right & span,
  .progress-label-top-right & span {
    color: @text-color;
    position: absolute;
    text-align: right;
  }
  .progress-label-left & span {
    font-size: @font-size-large;
    left: -40px;
    top: 0;
    width: 35px;
  }
  .progress-label-right & span,
  .progress-label-top-right & span {
    font-size: @font-size-small;
    overflow: hidden;
    right: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    strong {
      font-weight: 600;
    }
  }
  .progress-label-right & span {
    max-width: @progress-description-label-width;
    top: 0;
  }
  .progress-label-top-right & span {
    max-width: 47%;
    top: (-(@line-height-computed * 1.5)); // -30px
  }
  .progress-label-left.progress-sm & span,
  .progress-label-top-right.progress-sm & span {
    font-size: @font-size-base;
  }
  .progress-sm & {
    line-height: @progress-sm;
  }
  .progress-xs & {
    line-height: @progress-xs;
  }
}

.progress-bar-remaining {
  background: transparent;
}

.progress-container {
  position: relative;
  &.progress-description-left {
    padding-left :(@progress-description-label-width + 5);
  }
  &.progress-label-right {
    padding-right :(@progress-description-label-width + 5);
  }
}

.progress-description {
  margin-bottom: (@line-height-computed / 2); // 10px
  max-width: 52%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  .count {
    font-size: (@font-size-base * 1.667); // 20px
    font-weight: 300;
    line-height: 1;
    margin-right: 5px;
  }
  .fa, .fas, .far, .fab, .fal,
  .pficon {
    font-size: 14px;
    margin-right: 3px;
  }
  .progress-description-left & {
    left: 0;
    margin-bottom: 0;
    max-width: @progress-description-label-width;
    position: absolute;
    top: 0;
  }
  .tooltip {
    white-space: normal;
  }
}
