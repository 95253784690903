//
// Fonts
// --------------------------------------------------

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  src: url("@{font-path}/OpenSans-Light-webfont.eot"); /* IE9 Compat Modes */
  src: local("Open Sans Light"), local("OpenSans-Light"),
       url("@{font-path}/OpenSans-Light-webfont.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
       url("@{font-path}/OpenSans-Light-webfont.woff2") format("woff2"), /* Super Modern Browsers */
       url("@{font-path}/OpenSans-Light-webfont.woff") format("woff"), /* Modern Browsers */
       url("@{font-path}/OpenSans-Light-webfont.ttf") format("truetype"), /* Safari, Android, iOS */
       url("@{font-path}/OpenSans-Light-webfont.svg#OpenSans") format("svg"); /* Legacy iOS */
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: url("@{font-path}/OpenSans-Regular-webfont.eot"); /* IE9 Compat Modes */
  src: local("Open Sans"), local("OpenSans"),
       url("@{font-path}/OpenSans-Regular-webfont.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
       url("@{font-path}/OpenSans-Regular-webfont.woff2") format("woff2"), /* Super Modern Browsers */
       url("@{font-path}/OpenSans-Regular-webfont.woff") format("woff"), /* Modern Browsers */
       url("@{font-path}/OpenSans-Regular-webfont.ttf") format("truetype"), /* Safari, Android, iOS */
       url("@{font-path}/OpenSans-Regular-webfont.svg#OpenSans") format("svg"); /* Legacy iOS */
}
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 300;
  src: url("@{font-path}/OpenSans-LightItalic-webfont.eot"); /* IE9 Compat Modes */
  src: local("Open Sans Light Italic"), local("OpenSansLight-Italic"),
       url("@{font-path}/OpenSans-LightItalic-webfont.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
       url("@{font-path}/OpenSans-LightItalic-webfont.woff2") format("woff2"), /* Super Modern Browsers */
       url("@{font-path}/OpenSans-LightItalic-webfont.woff") format("woff"), /* Modern Browsers */
       url("@{font-path}/OpenSans-LightItalic-webfont.ttf") format("truetype"), /* Safari, Android, iOS */
       url("@{font-path}/OpenSans-LightItalic-webfont.svg#OpenSans") format("svg"); /* Legacy iOS */
}
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 400;
  src: url("@{font-path}/OpenSans-Italic-webfont.eot"); /* IE9 Compat Modes */
  src: local("Open Sans Italic"), local("OpenSans-Italic"),
       url("@{font-path}/OpenSans-Italic-webfont.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
       url("@{font-path}/OpenSans-Italic-webfont.woff2") format("woff2"), /* Super Modern Browsers */
       url("@{font-path}/OpenSans-Italic-webfont.woff") format("woff"), /* Modern Browsers */
       url("@{font-path}/OpenSans-Italic-webfont.ttf") format("truetype"), /* Safari, Android, iOS */
       url("@{font-path}/OpenSans-Italic-webfont.svg#OpenSans") format("svg"); /* Legacy iOS */
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  src: url("@{font-path}/OpenSans-Semibold-webfont.eot"); /* IE9 Compat Modes */
  src: local("Open Sans Semibold"), local("OpenSans-Semibold-webfont"),
       url("@{font-path}/OpenSans-Semibold-webfont.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
       url("@{font-path}/OpenSans-Semibold-webfont.woff2") format("woff2"), /* Super Modern Browsers */
       url("@{font-path}/OpenSans-Semibold-webfont.woff") format("woff"), /* Modern Browsers */
       url("@{font-path}/OpenSans-Semibold-webfont.ttf") format("truetype"), /* Safari, Android, iOS */
       url("@{font-path}/OpenSans-Semibold-webfont.svg#OpenSans") format("svg"); /* Legacy iOS */
}
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 600;
  src: url("@{font-path}/OpenSans-SemiboldItalic-webfont.eot"); /* IE9 Compat Modes */
  src: local("Open Sans Semibold Italic"), local("OpenSans-SemiboldItalic-webfont"),
       url("@{font-path}/OpenSans-SemiboldItalic-webfont.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
       url("@{font-path}/OpenSans-SemiboldItalic-webfont.woff2") format("woff2"), /* Super Modern Browsers */
       url("@{font-path}/OpenSans-SemiboldItalic-webfont.woff") format("woff"), /* Modern Browsers */
       url("@{font-path}/OpenSans-SemiboldItalic-webfont.ttf") format("truetype"), /* Safari, Android, iOS */
       url("@{font-path}/OpenSans-SemiboldItalic-webfont.svg#OpenSans") format("svg"); /* Legacy iOS */
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  src: url("@{font-path}/OpenSans-Bold-webfont.eot"); /* IE9 Compat Modes */
  src: local("Open Sans Bold"), local("OpenSans-Bold"),
       url("@{font-path}/OpenSans-Bold-webfont.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
       url("@{font-path}/OpenSans-Bold-webfont.woff2") format("woff2"), /* Super Modern Browsers */
       url("@{font-path}/OpenSans-Bold-webfont.woff") format("woff"), /* Modern Browsers */
       url("@{font-path}/OpenSans-Bold-webfont.ttf") format("truetype"), /* Safari, Android, iOS */
       url("@{font-path}/OpenSans-Bold-webfont.svg#OpenSans") format("svg"); /* Legacy iOS */
}
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 700;
  src: url("@{font-path}/OpenSans-BoldItalic-webfont.eot"); /* IE9 Compat Modes */
  src: local("Open Sans Bold Italic"), local("OpenSans-BoldItalic"),
       url("@{font-path}/OpenSans-BoldItalic-webfont.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
       url("@{font-path}/OpenSans-BoldItalic-webfont.woff2") format("woff2"), /* Super Modern Browsers */
       url("@{font-path}/OpenSans-BoldItalic-webfont.woff") format("woff"), /* Modern Browsers */
       url("@{font-path}/OpenSans-BoldItalic-webfont.ttf") format("truetype"), /* Safari, Android, iOS */
       url("@{font-path}/OpenSans-BoldItalic-webfont.svg#OpenSans") format("svg"); /* Legacy iOS */
}
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 800;
  src: url("@{font-path}/OpenSans-ExtraBoldItalic-webfont.eot"); /* IE9 Compat Modes */
  src: local("Open Sans Extrabold Italic"), local("OpenSans-ExtraboldItalic"),
       url("@{font-path}/OpenSans-ExtraBoldItalic-webfont.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
       url("@{font-path}/OpenSans-ExtraBoldItalic-webfont.woff2") format("woff2"), /* Super Modern Browsers */
       url("@{font-path}/OpenSans-ExtraBoldItalic-webfont.woff") format("woff"), /* Modern Browsers */
       url("@{font-path}/OpenSans-ExtraBoldItalic-webfont.ttf") format("truetype"), /* Safari, Android, iOS */
       url("@{font-path}/OpenSans-ExtraBoldItalic-webfont.svg#OpenSans") format("svg"); /* Legacy iOS */
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 800;
  src: url("@{font-path}/OpenSans-ExtraBold-webfont.eot"); /* IE9 Compat Modes */
  src: local("Open Sans Extrabold"), local("OpenSans-Extrabold"),
       url("@{font-path}/OpenSans-ExtraBold-webfont.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
       url("@{font-path}/OpenSans-ExtraBold-webfont.woff2") format("woff2"), /* Super Modern Browsers */
       url("@{font-path}/OpenSans-ExtraBold-webfont.woff") format("woff"), /* Modern Browsers */
       url("@{font-path}/OpenSans-ExtraBold-webfont.ttf") format("truetype"), /* Safari, Android, iOS */
       url("@{font-path}/OpenSans-ExtraBold-webfont.svg#OpenSans") format("svg"); /* Legacy iOS */
}
