//
// Syntax Highlighting for google-code-prettify
// --------------------------------------------------

.prettyprint {
  .atn,
  .com,
  .fun,
  .var {
    color: @syntax-3;
  }

  .atv,
  .str {
    color: @syntax-1;
  }

  .clo,
  .dec,
  .kwd,
  .opn,
  .pln,
  .pun {
    color: @syntax-2;
  }

  .lit,
  .tag,
  .typ {
    color: @syntax-4;
  }

  ol.linenums {
    margin-bottom: 0;
  }
}
