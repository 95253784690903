/*
 *  Copyright 2022 Red Hat
 *
 *  Licensed under the Apache License, Version 2.0 (the "License");
 *  you may not use this file except in compliance with the License.
 *  You may obtain a copy of the License at
 *
 *      https://www.apache.org/licenses/LICENSE-2.0
 *
 *  Unless required by applicable law or agreed to in writing, software
 *  distributed under the License is distributed on an "AS IS" BASIS,
 *  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 *  See the License for the specific language governing permissions and
 *  limitations under the License.
 */
.autocomplete-suggestions {
  background-clip: padding-box;
  background-color: @dropdown-bg;
  border: 1px solid @dropdown-border;
  border-radius: @border-radius-base;
  box-sizing: border-box;
  .box-shadow(0 6px 12px rgba(0, 0, 0, .175));
  cursor: default;
  display: none;
  font-size: @font-size-base;
  left: 0;
  margin: 2px 0 0;
  max-height: 254px;
  min-width: 160px;
  overflow: hidden;
  overflow-y: auto;
  padding: 5px 0;
  position: absolute;
  text-align: left;
  top: 100%;
  width: 100%;
  z-index: 9999;
}

.autocomplete-suggestion {
  clear: both;
  color: @dropdown-link-color;
  display: block;
  font-weight: normal;
  line-height: @line-height-base;
  overflow: hidden;
  padding: 3px 10px;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;

  .address {
    color: #999;
  }
}

.autocomplete-suggestion.selected {
  background-color: @dropdown-link-hover-bg;
  color: @dropdown-link-hover-color;
  cursor: pointer;
  outline: 0;
  text-decoration: none;
}
