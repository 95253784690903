/*
 *  Copyright 2022 Red Hat
 *
 *  Licensed under the Apache License, Version 2.0 (the "License");
 *  you may not use this file except in compliance with the License.
 *  You may obtain a copy of the License at
 *
 *      https://www.apache.org/licenses/LICENSE-2.0
 *
 *  Unless required by applicable law or agreed to in writing, software
 *  distributed under the License is distributed on an "AS IS" BASIS,
 *  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 *  See the License for the specific language governing permissions and
 *  limitations under the License.
 */
.hal-search {
  display: flex;
  flex: 1 1 auto;
  flex-flow: row nowrap;

  &.has-button {
    border-collapse: separate;
    .form-group {
      .btn {
        .box-shadow(none);
        margin-left: -1px;
        &.btn-lg {
          font-size: (@font-size-large + .5); // to make button height consistent across browsers!
        }
        &.btn-sm {
          font-size: (@font-size-small - .3); // to make button height consistent across browsers!
        }
      }
      .form-control {
      }
    }
  }

  .form-group.has-clear {
    flex: 1;
  }

  .form-group {
    margin-bottom: 0;
    .btn {
      width: 25px;
    }
  }

  .has-clear {
    .clear {
      background: transparent; // IE8
      background: fade(@color-pf-white, 0%); // to make the whole button clickable in IE9+
      border: 0;
      height: (@input-height-base - 1);
      line-height: 1;
      padding: 0;
      position: absolute;
      right: 1px;
      top: 1px;
      width: 28px;
      &:focus {
        outline: none;
      }
    }
    .form-control {
      padding-right: 30px;
      &::-ms-clear {
        display: none;
      }
    }
    .input-lg + .clear {
      height: (@input-height-large - 2);
      width: 28px;
    }
    .input-sm + .clear {
      height: (@input-height-small - 2);
      width: 28px;
      span {
        font-size: (@font-size-base - 2);
      }
    }
    .search-pf-input-group {
      position: relative;
    }
  }
}
