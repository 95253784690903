.experimental-pf > * {
  border: 2px solid @color-pf-light-green-400;
}

.experimental-pf-bar {
  background-color: @color-pf-light-green-400;
  border: none;
  text-align: center;
  position: relative;
}

.experimental-pf-more-info {
  background-color: @color-pf-light-green-400;
  border: 0;
  color: @color-pf-black;
  display: block;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  position: static;
  @media (min-width: @screen-md-min) {
    padding: 0 10px;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
  }
}

.experimental-pf-text {
  @media (min-width: @screen-md-min) {
    padding-left: 150px;
    padding-right: 150px;
  }

  a {
    color: @color-pf-black;
    text-decoration: underline;
    &:hover {
      color: @color-pf-black;
      cursor: pointer;
    }
  }
}

.btn-experimental-pf {
  .button-variant(@btn-default-color; @color-pf-light-green-400; @color-pf-light-green-300; @color-pf-light-green-400; @color-pf-light-green-400);
}
