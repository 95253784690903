/*
 *  Copyright 2022 Red Hat
 *
 *  Licensed under the Apache License, Version 2.0 (the "License");
 *  you may not use this file except in compliance with the License.
 *  You may obtain a copy of the License at
 *
 *      https://www.apache.org/licenses/LICENSE-2.0
 *
 *  Unless required by applicable law or agreed to in writing, software
 *  distributed under the License is distributed on an "AS IS" BASIS,
 *  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 *  See the License for the specific language governing permissions and
 *  limitations under the License.
 */
/*
 * Adjust the top and bottom position to the header and footer used in HAL.
 */
.nav-pf-vertical-hal, .nav-pf-secondary-nav-hal {
  bottom: @hal-footer-height;
  min-height: 1px;
  top: (@hal-header-height - 1px);
  z-index: 1000; // lower z-index to make the breadcrumb dropdown visible again

  @media (max-width: 767px) {
    top: 38px;
  }
}

.external {
  .nav-pf-vertical-hal, .nav-pf-secondary-nav-hal {
    bottom: 0;
    top: 0;
  }
}