/*
 *  Copyright 2022 Red Hat
 *
 *  Licensed under the Apache License, Version 2.0 (the "License");
 *  you may not use this file except in compliance with the License.
 *  You may obtain a copy of the License at
 *
 *      https://www.apache.org/licenses/LICENSE-2.0
 *
 *  Unless required by applicable law or agreed to in writing, software
 *  distributed under the License is distributed on an "AS IS" BASIS,
 *  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 *  See the License for the specific language governing permissions and
 *  limitations under the License.
 */
.gradient (@color1; @color2) {
  background: @color1;
  background: -webkit-linear-gradient(top, @color1 0%, @color2 100%);
  background: linear-gradient(to bottom, @color1 0%, @color2 100%);
}

.jstree-theme (@base-height, @image, @image-height) {
  @correction: ((@image-height - @base-height) / 2);

  .jstree-node {
    min-height: @base-height;
    line-height: @base-height;
    margin-left: @base-height;
    min-width: @base-height;
  }
  .jstree-anchor {
    line-height: @base-height;
    height: @base-height;
  }
  .jstree-icon {
    width: @base-height;
    height: @base-height;
    line-height: @base-height;
  }
  .jstree-icon:empty {
    width: @base-height;
    height: @base-height;
    line-height: @base-height;
  }
  &.jstree-rtl .jstree-node {
    margin-right: @base-height;
  }
  .jstree-wholerow {
    height: @base-height;
  }

  .jstree-node,
  .jstree-icon {
    background-image: url("@{image}");
  }
  .jstree-node {
    background: repeat-y ((@image-height * 9 + @correction) * -1) (@correction * -1);
  }
  .jstree-last {
    background: transparent;
  }

  .jstree-open > .jstree-ocl {
    background-position: ((@image-height * 4 + @correction) * -1) (@correction * -1);
  }
  .jstree-closed > .jstree-ocl {
    background-position: ((@image-height * 3 + @correction) * -1) (@correction * -1);
  }
  .jstree-leaf > .jstree-ocl {
    background-position: ((@image-height * 2 + @correction) * -1) (@correction * -1);
  }

  .jstree-themeicon {
    background-position: ((@image-height * 8 + @correction) * -1) (@correction * -1);
  }

  > .jstree-no-dots {
    .jstree-node,
    .jstree-leaf > .jstree-ocl {
      background: transparent;
    }
    .jstree-open > .jstree-ocl {
      background-position: ((@image-height * 1 + @correction) * -1) (@correction * -1);
    }
    .jstree-closed > .jstree-ocl {
      background-position: (@correction * -1) (@correction * -1);
    }
  }

  .jstree-disabled {
    background: transparent;
    &.jstree-hovered {
      background: transparent;
    }
    &.jstree-clicked {
      background: #efefef;
    }
  }

  .jstree-checkbox {
    background-position: ((@image-height * 5 + @correction) * -1) (@correction * -1);
    &:hover {
      background-position: ((@image-height * 5 + @correction) * -1) ((@image-height * 1 + @correction) * -1);
    }
  }

  &.jstree-checkbox-selected .jstree-clicked, .jstree-checked {
    > .jstree-checkbox {
      background-position: ((@image-height * 7 + @correction) * -1) (@correction * -1);
      &:hover {
        background-position: ((@image-height * 7 + @correction) * -1) ((@image-height * 1 + @correction) * -1);
      }
    }
  }
  .jstree-anchor {
    > .jstree-undetermined {
      background-position: ((@image-height * 6 + @correction) * -1) (@correction * -1);
      &:hover {
        background-position: ((@image-height * 6 + @correction) * -1) ((@image-height * 1 + @correction) * -1);
      }
    }
  }
  .jstree-checkbox-disabled {
    opacity: 0.8;
    filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'jstree-grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#jstree-grayscale"); /* Firefox 10+ */
    filter: gray; /* IE6-9 */
    -webkit-filter: grayscale(100%); /* Chrome 19+ & Safari 6+ */
  }

  > .jstree-striped {
    background-size: auto (@base-height * 2);
  }

  &.jstree-rtl {
    .jstree-node {
      background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAACAQMAAAB49I5GAAAABlBMVEUAAAAdHRvEkCwcAAAAAXRSTlMAQObYZgAAAAxJREFUCNdjAAMOBgAAGAAJMwQHdQAAAABJRU5ErkJggg==");
      background-position: 100% 1px;
      background-repeat: repeat-y;
    }
    .jstree-last {
      background: transparent;
    }
    .jstree-open > .jstree-ocl {
      background-position: ((@image-height * 4 + @correction) * -1) ((@image-height * 1 + @correction) * -1);
    }
    .jstree-closed > .jstree-ocl {
      background-position: ((@image-height * 3 + @correction) * -1) ((@image-height * 1 + @correction) * -1);
    }
    .jstree-leaf > .jstree-ocl {
      background-position: ((@image-height * 2 + @correction) * -1) ((@image-height * 1 + @correction) * -1);
    }
    > .jstree-no-dots {
      .jstree-node,
      .jstree-leaf > .jstree-ocl {
        background: transparent;
      }
      .jstree-open > .jstree-ocl {
        background-position: ((@image-height * 1 + @correction) * -1) ((@image-height * 1 + @correction) * -1);
      }
      .jstree-closed > .jstree-ocl {
        background-position: (@correction * -1) ((@image-height * 1 + @correction) * -1);
      }
    }
  }
  .jstree-themeicon-custom {
    background-color: transparent;
    background-image: none;
    background-position: 0 0;
  }

  > .jstree-container-ul .jstree-loading > .jstree-ocl {
    background: url("@{image-path}throbber.gif") center center no-repeat;
  }

  .jstree-file {
    background: url("@{image}") ((@image-height * 3 + @correction) * -1) ((@image-height * 2 + @correction) * -1) no-repeat;
  }
  .jstree-folder {
    background: url("@{image}") ((@image-height * 8 + @correction) * -1) (@correction * -1) no-repeat;
  }

  > .jstree-container-ul > .jstree-node {
    margin-left: 0;
    margin-right: 0;
  }

  // drag'n'drop
  #jstree-dnd& {
    line-height: @base-height;
    padding: 0 4px;
    .jstree-ok,
    .jstree-er {
      background-image: url("@{image-path}32px.png");
      background-repeat: no-repeat;
      background-color: transparent;
    }
    i {
      background: transparent;
      width: @base-height;
      height: @base-height;
      line-height: @base-height;
    }
    .jstree-ok {
      background-position: (@correction * -1) ((@image-height * 2 + @correction) * -1);
    }
    .jstree-er {
      background-position: ((@image-height * 1 + @correction) * -1) ((@image-height * 2 + @correction) * -1);
    }
  }
}
