//
// Card View
// --------------------------------------------------

.card-pf-view {
  border: 2px solid transparent;
  .card-pf-heading-kebab {
    .dropdown-kebab-pf {
      margin-top: -3px;
    }
    + .progress-pf-legend {
      p { margin-bottom: 0; }
      .progress {
        margin-bottom: 7px;
        margin-top: 16px;
      }
    }
  }
  .card-pf-info {
    margin-top: 15px;
    strong {
      font-size: ceil((@font-size-base + 1));
      margin-right: 10px;
    }
  }
  .card-pf-item {
    display: inline-block;
    font-size: @font-size-h3;
    padding: 0 13px 0 15px;
    &:first-child { padding-left: 0; }
    &:last-child { padding-right: 0; }
    + .card-pf-item { border-left: 1px solid @card-pf-border-color; }
    .fa-check { color: @brand-success; }
    .fa, .fas, .far, .fab, .fal,
    .pficon {
      + .card-pf-item-text { margin-left: 10px; }
    }
  }
  .card-pf-items { margin-top: 15px; }
  .card-pf-title {
    font-size: ceil((@font-size-base * 1.6666));
    font-weight: 300;
    margin-bottom: 0;
    margin-top: 15px;
    .fa, .fas, .far, .fab, .fal,
    .pficon {
      font-size: ceil((@font-size-base * 1.5));
      margin-right: 2px;
    }
    .col-lg-2 & { font-size: @font-size-h3; }
  }
  .card-pf-top-element .card-pf-icon-circle {
    border: 2px solid @color-pf-blue-300;
    border-radius: 50%;
    display: block;
    font-size: 46px;
    height: 106px;
    line-height: 102px;
    margin: 0 auto;
    text-align: center;
    width: 106px;
    .col-lg-2 & {
      font-size: 23px;
      height: 54px;
      line-height: 50px;
      width: 54px;
    }
  }
  .card-pf-view-checkbox {
    position: absolute;
    top: 11px;
    left: 15px;
    input[type=checkbox] { display: none; }
  }
  &.card-pf-view-multi-select {
    .card-pf-view-checkbox {
      input[type=checkbox] {
        display: block;
        @media(min-width:@screen-sm-min) {
          visibility: hidden;
          &:checked { visibility: visible; }
        }
      }
    }
    &:hover .card-pf-view-checkbox input[type=checkbox] { visibility: visible; }
  }
  &.card-pf-view-select {
    position: relative;
    &:hover { .box-shadow(0 1px 6px fade(@color-pf-black, 35%)); }
    &.active { border: 2px solid @card-pf-selected-border-color; }
  }
  &.card-pf-view-single-select { cursor: pointer; }
  &.card-pf-view-xs {
    .card-pf-title {
      font-size: 16px;
      font-weight: normal;
      margin-bottom: 10px;
      .fa, .fas, .far, .fab, .fal,
      .pficon {
        font-size: 14px;
        margin-right: 5px;
      }
    }
  }
}
