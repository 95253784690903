/*
 *  Copyright 2022 Red Hat
 *
 *  Licensed under the Apache License, Version 2.0 (the "License");
 *  you may not use this file except in compliance with the License.
 *  You may obtain a copy of the License at
 *
 *      https://www.apache.org/licenses/LICENSE-2.0
 *
 *  Unless required by applicable law or agreed to in writing, software
 *  distributed under the License is distributed on an "AS IS" BASIS,
 *  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 *  See the License for the specific language governing permissions and
 *  limitations under the License.
 */
.eap-home-row {
  .clearfix();
  padding: 1.5% 3%;
  margin-left: -3%;
  line-height: 1.5;
}

.eap-home-col {
  float: left;
  width: 47%;
  margin-left: 3%
}

.eap-home-title {
  margin: 0 3% 3% 3%;
  h1 {
    font-weight: 300;
  }
  p {
    float: right;
  }
}

.eap-home-module {
  .clearfix();
}

.eap-home-module-icon {
  float: left;
  img {
    width: 40px;
    height: auto;
  }
}

.eap-home-module-container {
  margin-left: 50px;
  p {
    margin: 0 0 5px 0;
  }
  ol {
    margin: 0 0 5px 0;
    padding: 0;
    list-style-position: inside;
  }
  ul {
    list-style: none;
    margin: 0 0 5px 0;
    padding: 0;
  }
}

.eap-home-module-header {
  margin-bottom: 20px;
  h2 {
    margin: 0 0 3px 0;
    font-weight: 300;
    //    background: red;
    line-height: 20px;
  }
  p {
    margin: 0;
  }
}

.eap-home-module-col {
  float: left;
  width: 50%;
}

.eap-toggle-controls {
  margin-bottom: 10px;
  a {
    display: inline-block;
    padding: 0 20px;
    border-right: 1px solid #ccc;
    &:last-child {
      border: none;
    }
    &:first-child {
      padding-left: 0;
    }
  }
}

.eap-home-section-icon {
  width: .8em;
}

.eap-toggle-container {
  margin-bottom: 10px;
}
