//
// Skip to content
// --------------------------------------------------
.skiplink-pf {
  position: absolute;
  top: @skiplink-pf-top;
  left: -300%; /* moves off screen */
  z-index: @zindex-modal;
}
.skiplink-pf:focus {
  left: @skiplink-pf-left;
}
