/*
 *  Copyright 2022 Red Hat
 *
 *  Licensed under the Apache License, Version 2.0 (the "License");
 *  you may not use this file except in compliance with the License.
 *  You may obtain a copy of the License at
 *
 *      https://www.apache.org/licenses/LICENSE-2.0
 *
 *  Unless required by applicable law or agreed to in writing, software
 *  distributed under the License is distributed on an "AS IS" BASIS,
 *  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 *  See the License for the specific language governing permissions and
 *  limitations under the License.
 */
.macro-list, .macro-editor, .no-macros {
  margin-top: @margin-large;
}

.macro-console, .no-macros {
  margin-bottom: @margin-large;
}

.macro-list {
  overflow-y: auto;
}

.macro-editor {
  margin-bottom: @margin-large;
}

.macro-editor {
  .copy {
    margin: 5px;
    outline: none;
    position: absolute;
    right: 20px;
    z-index: 10;

    &:focus {
      outline: none;
    }
  }
}
