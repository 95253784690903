//
// Bootstrap Switch
// --------------------------------------------------

.@{bootstrap-switch-base} {
  .@{bootstrap-switch-base}-handle-off,
  .@{bootstrap-switch-base}-handle-on {
    &.@{bootstrap-switch-base}-default {
      background: @bootstrap-switch-handle-default-bg-color;
    }
  }
  .@{bootstrap-switch-base}-label {
    background: @bootstrap-switch-bg-color;
    box-shadow: 0 0 2px fade(@color-pf-black, 40%);
    #gradient > .vertical(@bootstrap-switch-bg-color-start, @bootstrap-switch-bg-color-stop);
    position: relative;
    z-index: 9;
  }
}
