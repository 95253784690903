//
// DataTables
// --------------------------------------------------

// Deprecated
.ColVis_Button {
  &:active {
    &:focus {
      outline: none;
    }
  }
}

// Deprecated
.ColVis_catcher {
  position: absolute;
  z-index: 999;
}

// Deprecated
.ColVis_collection {
  background-color: @dropdown-bg;
  border: 1px solid @dropdown-border;
  border-radius: @border-radius-base;
  .box-shadow(0 6px 12px fade(@color-pf-black, 17.5%));
  background-clip: padding-box;
  list-style: none;
  margin: -1px 0 0;
  padding: 5px 10px;
  width: 150px;
  z-index: @zindex-dropdown;
  label {
    font-weight: normal;
    margin-bottom: 5px;
    margin-top: 5px;
    padding-left: 20px;
  }
}

// Deprecated
.ColVis_collectionBackground {
  background-color: @color-pf-white;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 998;
}

// Note: We won't be using this class with the Patternfly toolbar
.dataTables_header {
  background-color: @color-pf-black-150;
  border: 1px solid @table-border-color;
  border-bottom: none;
  padding: 5px;
  position: relative;
  text-align: center;
  .btn {
    .box-shadow(none);
  }
  // Deprecated
  .ColVis {
    position: absolute;
    right: 5px;
    text-align: left;
    top: 5px;
    + .dataTables_info {
      padding-right: 30px;
    }
  }
  .dataTables_filter {
    position: absolute;
    input {
      border: 1px solid @color-pf-black-400;
      height: 24px;
      @media (max-width: @screen-xs-max) {
        width: 100px;
      }
    }
  }
  .dataTables_info {
    padding: 2px 0;
    @media (max-width: @screen-xs) {
     text-align: right;
    }
    b {
      font-weight: bold;
    }
  }
}

// Note: We won't be using this class with the table view
.dataTables_footer {
  background-color: @color-pf-white;
  border: 1px solid @table-border-color;
  border-top: none;
  overflow: hidden;
}

// Not in use with latest example: May be used with pagination?
.dataTables_paginate {
  background: @color-pf-black-100;
  float: right;
  margin: 0;
  .pagination {
    float: left;
    margin: 0;
    > li {
      > span {
        border-color: @color-pf-white @color-pf-black-300 @color-pf-black-150;
        border-width: 0 1px;
        font-size: (@font-size-base + 4);
        font-weight: normal;
        padding: 0;
        text-align: center;
        width: 31px;
        &:hover,
        &:focus {
          .reset-filter();
        }
      }
      &.last > span {
        border-right: none;
      }
      &.disabled > span {
        background: @color-pf-black-150;
        border-left-color: @color-pf-black-200;
        border-right-color: @color-pf-black-200;
        .reset-filter();
      }
    }
  }
  .pagination-input {
    float: left;
    font-size: @font-size-base;
    line-height: 1em;
    padding: 4px 15px 0;
    text-align: right;
    .paginate_input {
      border: 1px solid @color-pf-black-300;
      .box-shadow(inset 0 1px 1px fade(@color-pf-black, 7.5%));
      font-size: @font-size-base;
      font-weight: 600;
      height: 19px;
      margin-right: 8px;
      padding-right: 3px;
      text-align: right;
      width: 30px;
    }
    .paginate_of {
      position: relative;
      b {
        margin-left: 3px;
      }
    }
  }
}

.dataTables_empty {
  background: @table-bg-accent;
}

/* Might need this for pagination?
.dataTables_wrapper {
  margin: @line-height-computed 0;
  @media (max-width: @screen-xs-max) {
    .table-responsive {
      margin-bottom: 0;
    }
  }
}
*/

// Not in use with latest example: May be used with column reordering?
.DTCR_clonedTable {
  background-color: fade(@color-pf-white, 70%);
  z-index: 202;
}

// Not in use with latest example: May be used with column reordering?
.DTCR_pointer {
  background-color: @link-color;
  width: 1px;
  z-index: 201;
}
