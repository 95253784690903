//
// Footer
// --------------------------------------------------

.footer-pf-alt, .footer-pf {
  background-color: @footer-pf-bg-color;
  color: @gray-light;
  font-size: @font-size-small;
  line-height: 17px; // whole px unit to avoid height differences among browsers
  padding-left: @footer-pf-padding-left;
  padding-top: @footer-pf-padding-top;
  .layout-pf-alt-fixed-with-footer &,  .layout-pf-fixed-with-footer & {
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    z-index: @zindex-navbar-fixed;
  }
}
