//
// Alerts
// --------------------------------------------------

.alert {
  border-width: 1px;
  padding-left: 47px;
  padding-right: (@alert-padding + 3);
  position: relative;
  word-wrap: break-word;
  .alert-link {
    color: @link-color;
    &:hover {
      color: @link-hover-color;
    }
  }
  > .btn.pull-right {
    margin-top: -3px;
  }
  > .pficon {
    font-size: 22px;
    position: absolute;
    left: 13px;
    top: 10px;
  }
  .close {
    .opacity(.85);
    &:hover,
    &:focus {
      .opacity(1);
    }
  }
  .pficon-info {
    color: @color-pf-black-700;
  }
}

.alert-dismissable {
  padding-right: (@alert-padding + 17);
  .close {
    right: -13px;
    top: 1px;
  }
}
