/*
 *  Copyright 2022 Red Hat
 *
 *  Licensed under the Apache License, Version 2.0 (the "License");
 *  you may not use this file except in compliance with the License.
 *  You may obtain a copy of the License at
 *
 *      https://www.apache.org/licenses/LICENSE-2.0
 *
 *  Unless required by applicable law or agreed to in writing, software
 *  distributed under the License is distributed on an "AS IS" BASIS,
 *  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 *  See the License for the specific language governing permissions and
 *  limitations under the License.
 */
/**
 * jsTree
 * Override default theme at "/node_modules/jstree/src/themes/default/style.less"
 */
@theme-name: hal;
@hovered-bg-color: @hover-color;
@hovered-shadow-color: #cccccc;
@disabled-color: #666666;
@disabled-bg-color: #efefef;
@clicked-bg-color: @active-item-color;
@clicked-shadow-color: @active-item-color;
@clicked-gradient-color-1: @active-item-color;
@clicked-gradient-color-2: @active-item-color;
@search-result-color: #8b0000;
@mobile-wholerow-bg-color: #ebebeb;
@mobile-wholerow-shadow: #666666;
@mobile-wholerow-bordert: rgba(255, 255, 255, 0.7);
@mobile-wholerow-borderb: rgba(64, 64, 64, 0.2);
@responsive: false;
@image-path: "/node_modules/jstree/src/themes/default/";
@base-height: 40px;

/*
 * Import a copy of the the original jsTree *.less files.
 * Since we need to run Less using 'strictMath: true' (due to rules in PF wizard.less),
 * all calculations must be enclosed in ()!
 */
@import "jstree/mixins.less";
@import "/node_modules/jstree/src/themes/base.less";
@import "/node_modules/jstree/src/themes/main.less";

.tree-container {
  margin-top: @margin-small;
  overflow: scroll;
}
