//
// List View
// --------------------------------------------------


.list-view-pf {
  .list-group-item {
    align-items: flex-start;
    background-clip: padding-box;
    border-color: transparent #fff;
    border-style: solid;
    border-width: 1px;
    .clearfix(); //IE9 fallback
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 0;
    padding-top: 0;
    &.list-view-pf-expand-active {
      background-color: @list-view-active-bg;
      box-shadow: 0 2px 6px rgba(3, 3, 3, .2);
      z-index: 1;
    }
    &.active {
      color: @list-group-link-color;
      background-color: @list-view-active-bg;
      background-clip: border-box;
      border-color: @list-view-active-border transparent transparent;
      z-index:auto;
    }
    &:hover {
      background-color: @list-view-hover-bg;
      border-left-color: transparent;
      border-right-color: transparent;
    }
    &.list-view-pf-expand-active {
      border: solid 1px @list-view-active-border;
      &:first-child {
        border-top-color: @list-view-active-border;
      }
    }
    &:first-child {
      border-top: 1px solid transparent;
    }
    @media (min-width: @screen-md-min) {
      align-items: center;
    }
  }
  .list-group-item-heading {
    font-size: @font-size-h3;
    small {
      display: block;
      font-size: (@font-size-base * .8);
      font-weight: 400;
    }
    @media (min-width: @screen-md-min) {
      flex:     1 0 ~"calc(25% - 20px)";
      float: left; // IE9 fallback
      font-size: @font-size-base;
      margin: 0 (@grid-gutter-width/2) 0 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: ~"calc(25% - 20px)"; // IE9 fallback
    }
  }
  .list-group-item-text {
    color: currentColor !important; // to overwrite color change when active.
    margin-bottom: 10px;
    @media (min-width: @screen-md-min) {
      flex:     1 0 auto; // it covers whats left from the title
      float: left; // IE9 fallback
      margin: 0 @grid-gutter-width 0 0;
      width: ~"calc(75% - 40px)"; // IE9 fallback
    }
  }
  .close {
    float: none;
    position: absolute;
    right: 15px;
  }
}
.list-view-pf-actions {
  float: right; // IE9 fallback
  margin-bottom: (@grid-gutter-width/2);
  margin-left: (@grid-gutter-width/2);
  margin-top: (@grid-gutter-width/2);
  order: 2;
  button,
  > a,
  .dropdown-kebab-pf {
    margin-left: (@grid-gutter-width/4);
  }
  .list-view-pf-top-align & {
    align-self: flex-start;
  }
}
.list-view-pf-additional-info {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  @media (min-width: @screen-md-min) {
    flex: 1 0 auto; // it covers whats left from summary
    float: left; // IE9 fallback
    width: 50%; // IE9 fallback
  }
}
.list-view-pf-additional-info-item {
  align-items: center;
  display: inline-block;
  display: flex;
  margin-right: (@grid-gutter-width/2);
  max-width:100%;
  text-align: center;
  &.list-view-pf-additional-info-item-stacked {
    text-align: center;
    flex-direction: column;
    strong {
      font-size: @font-size-h5;
      line-height: 1em;
    }
  }
  .pficon, .fa, .fas, .far, .fab, .fal {
    font-size: @font-size-h3;
    margin-right: (@grid-gutter-width/4);
  }
  strong {
    font-size: @font-size-h3;
    font-weight: 600;
    margin-right: 5px;
  }
  &:last-child {
    margin-right: 0;
  }
}
.list-view-pf-additional-info-item-donut-chart { width: 60px; }
.list-view-pf-body {
  align-items: center;
  display: table-cell; //IE9 fallback
  flex:     1;
  min-width: 0;
  vertical-align: top; //IE9 fallback
  width: 100%; // IE9 fallback, it extends the cell to size of the container
  @media (min-width: @screen-md-min) {
    align-items: center;
    display: flex;
    flex-direction: row;
  }
}
.list-view-pf-checkbox {
  border-right: 1px solid @list-view-divider;
  float: left; //IE9 fallback
  margin-bottom: (@grid-gutter-width/2);
  margin-right: 15px;
  margin-top: (@grid-gutter-width/2);
  padding: 3px (@grid-gutter-width/4) 3px 0;
  .list-view-pf-top-align & {
    align-self: flex-start;
  }
}
.list-view-pf-description {
  flex:     1 0 50%;
  .list-view-pf-stacked & {
    display: block;
    flex: none; // Fix FF
  }
  @media (min-width: @screen-md-min) {
    align-items: center;
    display: flex;
    float: left; //IE9 fallback
    width: 50%; //IE9 fallback
  }
}
.list-view-pf-left {
  display: table-cell; //IE9 fallback
  padding-right: (@grid-gutter-width/2);
  text-align: center;
  vertical-align: top; //IE9 fallback
  .list-view-pf-calendar {
    font-size: @font-size-small;
    line-height: 1em;
    strong {
      display: block;
      font-size: (@font-size-h2 * 2);
      font-weight: 300;
      line-height: 1em;
    }
  }
  .pficon, .fa, .fas, .far, .fab, .fal {
    border-radius: 50%;
    font-size: 2em;
    // -md is out of alpha order to get correct bg on -danger
    &.list-view-pf-icon-md {
      background-color: @alert-info-bg;
      height: 50px;
      line-height: 50px;
      width: 50px;
    }
    &.list-view-pf-icon-danger {
      background-color: @alert-danger-bg;
      color: @alert-danger-border;
    }
    &.list-view-pf-icon-info {
      color: @alert-info-border;
    }
    &.list-view-pf-icon-lg {
      background-color: @alert-info-bg;
      height: 60px;
      line-height: 60px;
      width: 60px;
    }
    &.list-view-pf-icon-sm {
      border: 2px solid @list-view-accented-border;
      font-size: 1.4em;
      height: 30px;
      line-height: 30px;
      width: 30px;
      &:before {
        display: block;
        line-height: 26px;
      }
    }
    &.list-view-pf-icon-success {
      background-color: @alert-success-bg;
      color: @alert-success-border;
    }
    &.list-view-pf-icon-warning {
      background-color: @alert-warning-bg;
      color: @alert-warning-border;
    }
  }
}
.list-view-pf-main-info {
  align-items: flex-start;
  display: flex;
  flex: 1;
  min-width: 0;
  padding-bottom: (@grid-gutter-width/2);
  padding-top: (@grid-gutter-width/2);
  @media (min-width: @screen-md-min) {
    align-items: center;
    .list-view-pf-top-align & {
      align-items: flex-start;
    }
  }
}
.list-view-pf-stacked {
  .list-group-item-heading {
    float: none; // IE9 fallback
    font-size: @font-size-h3;
    line-height: 1.2em;
    margin-bottom: 5px;
    margin-right: @grid-gutter-width;
    width: auto; // IE9 fallback
  }
  .list-group-item-text {
    float: none;
    width: auto;
  }
}
.list-view-pf-view {
  background: @list-group-top-border;
  border: none;
  margin-top: 30px;
}
.list-group-item-header {
  box-sizing: content-box;
  cursor: pointer;
  margin: 0 -15px;
  padding: 0 15px; //filling the width of the list item
  width: 100%;
}
.list-view-pf-expand {
  cursor: pointer;
  float: left; //IE9 fallback
  margin-bottom: (@grid-gutter-width/2);
  margin-right: 2px;
  margin-top: (@grid-gutter-width/2);
  padding: 3px 0;
  &.active,
  &:hover {
    color: @link-color;
  }
  .list-view-pf-additional-info-item & {
    margin: 0;
    padding: 0;
  }
  .fa-angle-right {
    cursor: pointer;
    font-size: (@font-size-large+3);
    margin-right: 5px;
    margin-top: 2px;
    width: 10px;
  }
}
.list-group-item-container {
  background: #fff;
  border-top: solid 1px @list-view-active-border;
  box-sizing: content-box;
  margin: -1px -15px 0;
  order: 3;
  padding: 15px;
  position: relative;
  width: 100%;
}
.list-view-pf-editable {
  &.active,
  &.list-view-pf-create {
    .list-view-pf-editor,
    .list-view-pf-editor.bootstrap-select,
    .bootstrap-switch,
    .list-view-pf-actions .list-view-pf-save,
    .list-view-pf-actions .list-view-pf-cancel {
      display: initial;
    }
    .list-view-pf-edit,
    .list-view-pf-value:not(.list-view-pf-readonly),
    .dropdown-kebab-pf {
      display: none;
    }
  }
  .list-view-pf-editor,
  .list-view-pf-editor.bootstrap-select,
  .bootstrap-switch,
  .list-view-pf-actions .list-view-pf-save,
  .list-view-pf-actions .list-view-pf-cancel {
    display: none;
  }
  &.active {
    .list-view-pf-additional-info {
      @media (min-width: @screen-sm-min) {
        margin-top: (@grid-gutter-width/2);
      }
      @media (min-width: @screen-md-min) {
        margin-top: 0;
      }
      .list-view-pf-additional-info-item {
        margin-top: (@grid-gutter-width/2);
        @media (min-width: @screen-sm-min) {
          margin-top: 0;
        }
      }
    }
  }
  .list-view-pf-textbox {
    margin-right: (@grid-gutter-width/2);
    width: 100%;
  }
  .list-view-pf-readonly {
    color: @color-pf-black-500;
  }
  .list-view-pf-actions {
    margin: (@grid-gutter-width/4) 0;
    width: 100px;
    text-align: right;
    .list-view-pf-save,
    .list-view-pf-cancel {
      font-size: @font-size-h1;
      @media (max-width: @screen-sm-min) {
        margin-left: 0;
      }
    }
  }
  .list-view-pf-description {
    flex: 100%;
    @media (min-width: @screen-sm-min) {
      flex: 100%;
    }
    @media (min-width: @screen-md-min) {
      flex: 25%;
    }
  }
  .list-view-pf-additional-info {
    flex: 100%;
    .list-view-pf-additional-info-item {
      flex: 100%;
    }
    @media (min-width: @screen-sm-min) {
      flex: 100%;
      .list-view-pf-additional-info-item {
        flex: 1;
      }
    }
    @media (min-width: @screen-md-min) {
      flex: 75%;
      .list-view-pf-additional-info-item {
        flex: 1;
      }
    }
  }
}
