//
// Toast notifications
// --------------------------------------------------

.toast-pf {
  background-color: @color-pf-white;
  border-color: @color-pf-black-400;
  box-shadow: 0 2px 6px fade(@color-pf-black, 20%);
  padding-left: 68px; //15px space between the icon and the text
  margin-bottom: 5px;
  &.alert-danger > .pficon  { background-color: @alert-danger-border; }
  &.alert-info > .pficon    { background-color: @alert-info-border; }
  &.alert-success > .pficon { background-color: @alert-success-border; }
  &.alert-warning > .pficon { background-color: @alert-warning-border; }
  .dropdown-kebab-pf { margin-left: 10px; }
  > .pficon {
    background-color: @gray-pf;
    bottom:  -1px;
    box-shadow: 2px 0 5px -2px fade(@color-pf-black, 20%);
    left: -1px;
    padding-top: 10px;
    text-align: center;
    top: -1px;
    width: 53px;
    &:before { color: fade(@color-pf-white, 74%); }
  }
  .toast-pf-action {
    margin-left: 15px;
  }
  .dropdown-kebab-pf .btn-link {
    padding-top: 0;
    padding-bottom: 0;
    vertical-align: text-bottom;
  }
  /* Medium devices (desktops, 992px and up) */
  @media (min-width: @screen-md-min) {
    display: inline-block;
  }
}
// Sets max width on toast notifications
.toast-pf-max-width {
 @media (min-width: @screen-md-min) {
    max-width: 31.1%; // sets the size to 4 cols;
  }
}
// To position the notification on the top right corner, used in context on cards layout
.toast-pf-top-right {
  left: (@grid-gutter-width / 2);
  position: absolute;
  right: (@grid-gutter-width / 2);
  top: 12px;
  z-index: 1055; // More than @zindex-modal
  /* Medium devices (desktops, 992px and up) */
  @media (min-width: @screen-md-min) {
    left: auto;
  }
}

.toast-notifications-list-pf {
  margin-left: (@grid-gutter-width / 2);
  position: fixed;
  right: (@grid-gutter-width / 2);
  top: @toast-notification-top;
  z-index: 1055;
  .toast-pf {
    clear: both;
    float: right;
  }
  @media (min-width: @screen-sm-min) {
    max-width: calc(50%);
  }
  @media (min-width: @screen-md-min) {
    max-width: calc(33.1% ); // sets the size to 4 cols;
  }
}
