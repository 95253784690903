//
//  Navigation Bar for use with vertical navigation
// --------------------------------------------------

.navbar-pf-vertical {
  background-color: @navbar-pf-vertical-bg-color;
  border: none;
  border-radius: 0;
  border-top: @navbar-pf-vertical-border-width @navbar-pf-vertical-border-style @navbar-pf-vertical-border-color;
  margin-bottom: 0;

  .infotip.bottom-right .arrow {
    left: 90%;
  }

  .layout-pf-fixed & {
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: @zindex-navbar-fixed;
  }

  .nav {
    &.navbar-nav > li > .dropdown-menu.infotip {
      margin-top: 0;
    }

    .nav-item-iconic {
      color: @navbar-pf-vertical-color;
      cursor: pointer;
      line-height: 1;
      max-height: (@navbar-pf-vertical-height - @navbar-pf-vertical-border-width); // to keep Firefox from oversizing icons
      padding: @navbar-pf-vertical-nav-item-iconic-padding;
      position: relative;
      margin: 0;
      border: 0;

      &:hover {
        outline: 0!important;
      }

      &:focus {
        outline: thin dotted;
        outline: 5px auto -webkit-focus-ring-color;
      }

      &:hover,
      &:focus {
        background-color: @navbar-pf-item-hover-bg-color;
        text-decoration: none;
      }

      .badge {
        background-color: @notification-badge-color;
        border-radius: 20px;
        color: @navbar-pf-item-active-color;
        cursor: pointer;
        font-size: (@font-size-base - 3);
        font-weight: 700;
        left: 20px;
        margin: 0;
        padding: 2px 4px;
        position: absolute;
        min-width: 10px;
        min-height: 10px;
        top: 18px;
        
        &.badge-pf-bordered {
          border: 1px solid @navbar-pf-vertical-bg-color;
        }
      }

      .caret,
      .fa, .fas, .far, .fab, .fal,
      .pficon {
        color: @navbar-pf-vertical-color;
        font-size: (@font-size-base + 4);
        position: relative;
      }

      .caret {
        font-size: @font-size-base;
        width: auto;
      }

      > .dropdown-title {
        position: relative;
        display: inline-block;
        line-height: 1.55;
      }

      > .pficon-user {
        padding-right: ~"calc(@{navbar-pf-item-icon-padding-right} + 3px)";
        float: left;
      }
    }

    .open > .nav-item-iconic {
      &,
      &:hover,
      &:focus {
        background: @navbar-pf-item-open-bg-color;
        color: @navbar-pf-item-active-color;
        outline: 0;

        .caret,
        .fa, .fas, .far, .fab, .fal,
        .pficon {
          color: @navbar-pf-item-active-color;
        }
      }
    }
  }

  .navbar-brand {
    color: @navbar-pf-vertical-navbar-brand-color;
    height: auto;
    margin: @navbar-pf-vertical-navbar-brand-margin;
    min-height: @navbar-pf-vertical-navbar-brand-min-height;
    padding: @navbar-pf-vertical-navbar-brand-padding;

    .navbar-brand-name {
      display: inline-block;
      margin: @navbar-pf-vertical-navbar-brand-name-margin;
      // hide once screen gets too small
      @media (max-width: @navbar-pf-vertical-navbar-brand-name-breakpoint) {
        display: none;
      }
    }

    .navbar-brand-icon {
      display: inline-block;
      margin: @navbar-pf-vertical-navbar-brand-icon-margin;
      max-height: @navbar-pf-vertical-navbar-brand-max-height;
    }

    .navbar-brand-txt {
      line-height: @navbar-pf-vertical-navbar-brand-line-height;
    }
  }

  .navbar-iconic {
    margin-right: 0;
  }

  .navbar-toggle {
    border: 0;
    display: block;
    float: left;
    margin: @navbar-pf-vertical-navbar-toggle-margin;

    &:hover,
    &:focus {
      .icon-bar {
        background: @navbar-pf-vertical-navbar-toggle-icon-bar-hover-bg;
      }
    }

    + .navbar-brand {
      margin-left: 0;
    }

    .icon-bar {
      background: @navbar-pf-vertical-color;
    }
  }
}
