.filter-pf-category-select {
  display: flex;
}
.filter-pf-category-select-value {
  border-left-width: 0;
}
.filter-pf-category-item {
  margin-bottom: 5px;
}
.filter-pf-category-label {
  font-weight: 700;
  margin-right: 5px;
  padding: 5px 0 6px 5px;
}
.filter-pf-select {
  .caret {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
  }
}
.filter-pf-select-dropdown {
  background-color: @color-pf-white;
  background-image: none;
  color: @color-pf-black-500;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  padding-right: 25px;
  text-align: left;
  .caret {
    font-style: normal;
  }
  &.filter-selected {
    font-style: normal;
    color: inherit;
  }
}
.filter-pf-active-label {
  margin-right: 5px;
}
