//
// Nav Vertical Alt
// --------------------------------------------------

.layout-pf-alt-fixed.transitions .nav-pf-vertical-alt {
  transition: @flyout-transition-pf;

  &.collapsed .list-group-item .list-group-item-value {
    transition: opacity 0ms 100ms, opacity 100ms linear;
  }

  .list-group-item {
    .badge {
      transition: @flyout-transition-pf;
    }

    .list-group-item-value {
      transition: opacity 500ms ease-out;
      transition-delay: 150ms;
    }
  }
}

.nav-pf-vertical-alt {
  background: @nav-pf-vertical-alt-bg-color;

  .ie9.layout-pf-alt-fixed & {
    box-sizing: content-box; // IE9 incorrectly sizes the width if using padding-box
  }

  .layout-pf-alt-fixed & {
    border-right: 1px solid @nav-pf-vertical-alt-border-color;
    bottom: 0;
    overflow-x: hidden;
    overflow-y: auto;
    left: 0;
    position: fixed;
    top: @navbar-pf-alt-height; // move out of way of navbar-pf-alt
    width: @nav-pf-vertical-alt-width;
    z-index: @zindex-navbar-fixed;

    &.collapsed {
      width: @nav-pf-vertical-alt-collapsed-width !important;

      .list-group-item {
        .badge {
          padding: 2px 3px;
          right: 21px;
          top: 36px;
        }

        .list-group-item-value {
          opacity: 0;
        }
      }
    }

    &.hidden {
      display: none;

      &.show-mobile-nav {
        box-shadow: 0 0 3px fade(@color-pf-black, 15%);
        display: block !important;
      }
    }
  }

  .layout-pf-alt-fixed-with-footer & {
    bottom: @footer-pf-alt-height;
  }

  .list-group {
    border-top: 0;
    margin-bottom: 0;
  }

  .list-group-item {
    padding: 0;

    a {
      color: @gray-dark;
      display: block;
      font-size: (@font-size-base + 2);
      height: @nav-pf-vertical-alt-link-height;
      padding: @nav-pf-vertical-alt-link-padding;
      position: relative;
      white-space: nowrap;

      &:focus {
        color: @gray-dark;
        text-decoration: none;
      }

      &:hover {
        color: @nav-pf-vertical-alt-active-color;
        text-decoration: none;
      }
    }

    &.active {
      background-color: @nav-pf-vertical-alt-bg-color;
      border-color: @list-group-border;

      &:before {
        background: @nav-pf-vertical-alt-active-color;
        content: " ";
        display: block;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 5px;
      }

      a {
        color: @nav-pf-vertical-alt-active-color;
      }
    }

    .badge {
      background: @gray-dark;
      border: 1px solid @nav-pf-vertical-alt-bg-color;
      border-radius: 3px;
      color: @nav-pf-vertical-alt-badge-color;
      font-weight: 700;
      font-size: (@font-size-base - 3);
      padding: 5px;
      position: absolute;
      right: 15px;
      text-align: center;
      top: 21px;

      &.notifications {
        background: @notification-badge-color;
      }
    }

    .fa, .fas, .far, .fab, .fal,
    .glyphicon,
    .pficon {
      float: left;
      font-size: 18px;
      line-height: 30px;
      margin-right: 10px;
      text-align: center;
      width: 18px;
    }

    .list-group-item-value {
      display: inline-block;
      line-height: 30px;
      opacity: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 140px;
    }

  }

  .list-group-item-separator {
    border-top-width: 5px;
  }

  .sidebar-pf & {
    margin-left: (-(@grid-gutter-width / 2));
    margin-right: (-(@grid-gutter-width / 2));
  }
}
