//
// Navbar
// --------------------------------------------------

.navbar-pf {
  background: @navbar-pf-bg-color;
  border: 0;
  border-radius: 0;
  border-top: 3px solid @navbar-pf-border-color;
  margin-bottom: 0;
  min-height: 0;
  .navbar-brand {
    color: @navbar-pf-active-color;
    height: auto;
    padding: 12px 0;
    margin: 0 0 0 20px;
    img {
      display: block;
    }
  }
  .navbar-collapse {
    border-top: 0;
    .box-shadow(none);
    padding: 0;
  }
  .navbar-header {
    border-bottom: 1px solid @navbar-pf-navbar-header-border-color;
    float: none;
  }
  .navbar-nav {
    margin: 0;
    > .active {
      > a,
      > button {
        &,
        &:hover,
        &:focus {
          background-color: @navbar-pf-item-open-bg-color;
          color: @navbar-pf-active-color;
        }
      }
    }
    > li {
      > a,
      > button {
        color: @navbar-pf-color;
        line-height: 1;
        padding: 10px 20px;
        text-shadow: none;
        &:hover,
        &:focus {
          color: @navbar-pf-active-color;
          text-decoration: none;
        }
        > .dropdown-title,
        > .pficon-user {
          position: relative;
        }
        > .pficon-user {
          padding-right: @navbar-pf-item-icon-padding-right;
        }
      }
    }
    > .open {
      > a,
      > button {
        &,
        &:hover,
        &:focus {
          background-color: @navbar-pf-item-open-bg-color;
          color: @navbar-pf-active-color;
          outline: 0;
        }
      }
    }
 .badge {
    background-color: @notification-badge-color;
    border-radius: 20px;
    color: @navbar-pf-item-active-color;
    cursor: pointer;
    font-size: (@font-size-base - 3);
    font-weight: 700;
    left: 26px;
    margin: 0;
    padding: 2px 4px;
    position: absolute;
    min-width: 10px;
    min-height: 10px;
    top: 6px;
    @media(min-width: @screen-sm-min) {
      left: auto;
      right: 6px;
      top: 3px;
    }
    &.badge-pf-bordered {
      border: 1px solid @navbar-pf-bg-color;
    }
}

    @media (max-width: @grid-float-breakpoint-max) {
      .active .dropdown-menu,
      .active .navbar-persistent,
      .open .dropdown-menu {
        background-color: @navbar-pf-navbar-nav-active-active-bg-color !important;
        margin-left: 0;
        padding-bottom: 0;
        padding-top: 0;
        > .active > a,
        .dropdown-submenu.open > a {
          &,
          &:hover,
          &:focus {
            background-color: @navbar-pf-item-open-bg-color !important;
            color: @navbar-pf-active-color;
          }
        }
        > li > a {
          background-color: transparent;
          border: 0;
          color: @navbar-pf-color;
          outline: none;
          padding-left: 30px;
          &:hover {
            color: @navbar-pf-active-color;
          }
        }
        .divider {
          background-color: @navbar-pf-navbar-header-border-color;
          margin: 0 1px;
        }
        .dropdown-header {
          padding-bottom: 0;
          padding-left: 30px;
        }
        .dropdown-submenu {
          &.open .dropdown-toggle {
            color: @navbar-pf-active-color;
          }
          &.pull-left {
            float: none !important;
          }
          > a:after {
            display: none;
          }
          .dropdown-header {
            padding-left: 45px;
          }
          .dropdown-menu {
            border: 0;
            bottom: auto;
            .box-shadow(none);
            display: block;
            float: none;
            margin: 0;
            min-width: 0;
            padding: 0;
            position: relative;
            left: auto;
            right: auto;
            top: auto;
            > li > a {
              padding: 5px 15px 5px 45px;
              line-height: 20px;
            }
            .dropdown-menu > li > a {
              padding-left: 60px;
            }
          }
        }
      }
      .active .navbar-persistent {
        .dropdown-submenu {
          &.open .dropdown-menu {
            display: block;
          }
          > a:after {
            display: inline-block !important;
            position: relative;
            right: auto;
            top: 1px;
          }
          .dropdown-menu {
            display: none;
          }
          .dropdown-submenu > a:after {
            display: none !important;
          }
        }
      }
      .context-bootstrap-select { // Also see bootstrap-select.less
        .open > .dropdown-menu {
          background-color: @color-pf-white !important;
          > .active > a {
            &,
            &:active {
              background-color: @dropdown-link-hover-bg !important;
              border-color: @dropdown-link-hover-border-color !important;
              color: @gray-dark !important;
              small {
                color: @gray-light !important;
              }
            }
          }
          > .disabled > a {
            color: @gray-light !important;
          }
          > .selected > a {
            &,
            &:active {
              background-color: @dropdown-link-active-bg !important;
              border-color: @dropdown-link-active-border-color !important;
              color: @color-pf-white !important;
              small {
                color: fade(@color-pf-white, 50%) !important;
              }
            }
          }
          li {
            > a.opt {
              border-bottom: 1px solid transparent;
              border-top: 1px solid transparent;
              color: @gray-dark;
              padding-left: 10px;
              padding-right: 10px;
            }
            & a {
              &:active small {
                color: fade(@color-pf-white, 50%) !important;
              }
              &:hover,
              &:focus {
                small {
                  color: @gray-light;
                }
              }
            }
          }
        }
        > .open > .dropdown-menu {
          padding-bottom: 5px;
          padding-top: 5px;
        }
      }
    }
  }
  .navbar-persistent {
    display: none;
  }
  .active > .navbar-persistent {
    display: block;
  }
  .navbar-primary {
    float: none;
    .context {
      border-bottom: 1px solid @navbar-pf-navbar-header-border-color;
      &.context-bootstrap-select .bootstrap-select.btn-group {
        &,
        &[class*="span"] {
          margin: 8px 20px 9px;
          width: auto; // known bug:  IE8 will make it as wide as the longest string
        }
      }
    }
    > li > .navbar-persistent > .dropdown-submenu > a {
      position: relative;
      &:after {
        content: @fa-var-angle-down;
        display: inline-block;
        font-family: @icon-font-name-fa;
        font-weight: normal;
        @media (max-width: @grid-float-breakpoint-max) {
          height: 10px;
          margin-left: 4px;
          vertical-align: baseline;
        }
      }
    }
  }
  .navbar-toggle {
    border: 0;
    margin: 0;
    padding: 10px 20px;
    &:hover,
    &:focus {
      background-color: transparent;
      outline: none;
      .icon-bar {
        .box-shadow(0 0 3px @color-pf-white);
      }
    }
    .icon-bar {
      background-color: @navbar-pf-icon-bar-bg-color;
    }
  }
  .navbar-utility {
    border-bottom: 1px solid @navbar-pf-navbar-header-border-color;
    li.dropdown {
      > a,
      > button {
        &:hover {
          outline: 0!important;
        }
        position: relative;
        > .fa, .fas, .far, .fab, .fal,
        .pficon {
          line-height: 0;
        }
      }
    }
    @media (max-width: @grid-float-breakpoint-max) {
      > li + li {
        border-top: 1px solid @navbar-pf-navbar-header-border-color;
      }
    }
  }
}

@media (min-width: @grid-float-breakpoint) {
  .navbar-pf {
    .navbar-brand {
      padding: @navbar-pf-navbar-navbar-brand-padding;
    }
    .navbar-nav > li {
      > a,
      > button {
        padding-bottom: 14px;
        padding-top: 14px;
      }
    }
    .navbar-persistent {
      font-size: @font-size-large;
    }
    .navbar-primary {
      font-size: @font-size-large;
      #gradient > .vertical(@navbar-pf-navbar-primary-bg-color-start, @navbar-pf-navbar-primary-bg-color-stop);
      &.persistent-secondary {
        .context {
          .dropdown-menu {
            top: auto;
          }
        }
        .dropup .dropdown-menu {
          bottom: -5px;
          top: auto;
        }
        > li {
          position: static;
          &.active {
            margin-bottom: 32px;
            > .navbar-persistent {
              display: block;
              left: 0;
              position: absolute;
            }
          }
          > .navbar-persistent {
            background: @navbar-pf-navbar-navbar-persistent-bg-color;
            border-bottom: 1px solid @navbar-pf-navbar-navbar-persistent-border-color;
            padding: 0;
            width: 100%;
            a {
              text-decoration: none !important;
            }
            > li {
              &.active {
                &,
                &:hover {
                  .tab-indicator(@background: @nav-tabs-active-link-hover-color, @left: 20px, @right: 20px);
                }
                > a,
                > a:hover,
                &:hover > a {
                  color: @link-color !important;
                }
                .active > a {
                  color: @navbar-pf-active-color;
                }
              }
              &.dropdown-submenu {
                &:hover > .dropdown-menu {
                  display: none;
                }
                &.open {
                  > .dropdown-menu {
                    display: block;
                    left: 20px;
                    margin-top: 1px;
                    top: 100%;
                  }
                  > .dropdown-toggle {
                    color: @gray-darker;
                    &:after {
                      border-top-color: @gray-darker;
                    }
                  }
                }
                > .dropdown-toggle {
                  padding-right: 35px !important;
                  &:after {
                    position: absolute;
                    right: 20px;
                    top: 10px;
                  }
                }
              }
              &:hover,
              &.open {
                .tab-indicator(@left: 20px, @right: 20px);
                > a {
                  color: @gray-darker;
                  &:after {
                    border-top-color: @gray-darker;
                  }
                }
              }
              > a {
                background-color: transparent;
                display: block;
                line-height: 1;
                padding: 9px 20px;
                &.dropdown-toggle {
                  padding-right: 35px;
                  &:after {
                    font-size: (@font-size-large + 1);
                    position: absolute;
                    right: 20px;
                    top: 9px;
                  }
                }
                &:hover {
                  color: @gray-darker;
                }
              }
              a {
                color: @gray-pf;
              }
            }
          }
        }
      }
      > li {
        > a,
        > button {
          border-bottom: 1px solid transparent;
          border-top: 1px solid transparent;
          position: relative;
          margin: -1px 0 0;
          &:hover {
            background-color: @navbar-pf-item-hover-bg-color;
            border-top-color: @navbar-pf-navbar-primary-hover-border-color;
            color: @navbar-pf-color;
            #gradient > .vertical(@navbar-pf-navbar-primary-hover-bg-color-start, @navbar-pf-navbar-primary-hover-bg-color-stop);
          }
        }
      }
      > .active,
      .open {
        > a,
        > button {
          &,
          &:hover,
          &:focus {
            background-color: @navbar-pf-item-open-bg-color;
            border-bottom-color: @navbar-pf-navbar-primary-active-bg-color-stop;
            border-top-color: @navbar-pf-navbar-primary-active-border-color;
            .box-shadow(none);
            color: @navbar-pf-active-color;
            #gradient > .vertical(@navbar-pf-navbar-primary-active-bg-color-start, @navbar-pf-navbar-primary-active-bg-color-stop);
          }
        }
      }
      li.context {
        &.context-bootstrap-select .filter-option {
          max-width: 160px;
          text-overflow: ellipsis;
        }
        &.dropdown {
          border-bottom: 0;
        }
        > a,
        &.context-bootstrap-select {
          background-color: @navbar-pf-navbar-primary-context-bg-color-stop;
          border-bottom-color: @navbar-pf-navbar-primary-context-border-color;
          border-right: 1px solid @navbar-pf-navbar-primary-context-border-color;
          border-top-color: @navbar-pf-navbar-primary-context-border-top-color;
          font-weight: 600;
          #gradient > .vertical(@navbar-pf-navbar-primary-context-bg-color-start, @navbar-pf-navbar-primary-context-bg-color-stop);
          &:hover {
            background-color: @navbar-pf-navbar-primary-context-hover-bg-color-stop;
            border-bottom-color: @navbar-pf-navbar-primary-context-hover-border-color;
            border-right-color: @navbar-pf-navbar-primary-context-hover-border-color;
            border-top-color: @navbar-pf-navbar-primary-context-hover-border-top-color;
            #gradient > .vertical(@navbar-pf-navbar-primary-context-hover-bg-color-start, @navbar-pf-navbar-primary-context-hover-bg-color-stop);
          }
        }
        &.open > a {
          background-color: @navbar-pf-navbar-primary-context-active-bg-color-stop;
          border-bottom-color: @navbar-pf-navbar-primary-context-active-border-color;
          border-right-color: @navbar-pf-navbar-primary-context-active-border-right-color;
          border-top-color: @navbar-pf-navbar-primary-context-active-border-top-color;
          #gradient > .vertical(@navbar-pf-navbar-primary-context-active-bg-color-start, @navbar-pf-navbar-primary-context-active-bg-color-stop);
        }
      }
    }
    .navbar-utility {
      border-bottom: 0;
      position: absolute;
      right: 0;
      top: 0;
      > .active,
      > .open {
        > a,
        > button {
          &,
          &:hover,
          &:focus {
            background: @navbar-pf-item-open-bg-color;
            color: @navbar-pf-active-color;
          }
        }
      }
      > li {
        > a,
        > button {
          border: solid @navbar-pf-navbar-utility-border-color;
          border-width: 0 0 0 1px;
          color: @navbar-pf-navbar-utility-color !important;
          padding: 7px 10px;
          font-size: @font-size-small;
          margin: 0;
          &:hover {
            background: @navbar-pf-item-hover-bg-color;
            border-left-color: @navbar-pf-navbar-utility-hover-border-color;
            outline: 0;
          }
        }
      }
      > li.open {
        > a,
        > button {
          border-left-color: @navbar-pf-navbar-utility-open-border-color;
          color: @navbar-pf-active-color !important;
        }
      }
      .open .dropdown-menu {
        left: auto;
        right: 0;
        .dropdown-menu {
          left: auto;
          right: 100%;
        }
      }
    }
    .navbar-utility .open .dropdown-menu {
        border-top-width: 0;
    }
    .open {
      &.bootstrap-select .dropdown-menu,
      .dropdown-submenu > .dropdown-menu {
        border-top-width: 1px !important;
      }
    }
  }
}
@media (max-width: 360px) {
  .navbar-pf {
    .navbar-brand {
      margin-left: 10px;
      width: 75%;
      img {
        height: auto;
        max-width: 100%;
      }
    }
    .navbar-toggle {
      padding-left: 0;
    }
  }
}
