//
// Labels
// --------------------------------------------------

.label {
  border-radius: 0;
  font-size: 100%;
  font-weight: 600;
  h1 &,
  h2 &,
  h3 &,
  h4 &,
  h5 &,
  h6 & {
    font-size: 75%;
  }
}
