//
// Bootstrap-Combobox
// --------------------------------------------------

.combobox-container {
  &.combobox-selected .glyphicon-remove {
    display: inline-block;
  }
  .caret {
    margin-left: 0;
  }
  .combobox::-ms-clear {
    display: none;
  }
  .dropdown-menu {
    margin-top: -1px;
    width: 100%;
  }
  .glyphicon-remove {
    display: none;
    top: auto;
    width: 12px;
    &:before {
      content: "\e60b";
      font-family: @icon-font-name-pf;
    }
  }
  .input-group-addon {
    .button-variant(@btn-default-color; @btn-default-bg; @btn-default-bg-img-start; @btn-default-bg-img-stop; @btn-default-border);
    position: relative; // IE8
    &:active {
      .box-shadow(inset 0 2px 8px fade(@color-pf-black, 20%));
    }
  }
}
