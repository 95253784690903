//
// Tree List View
// --------------------------------------------------


.tree-list-view-pf {
  background-color: @list-group-top-border;
  border: none;
  margin-top: 30px;
  overflow-x: hidden;
  > .list-group-item:first-child {
    border-top: 1px solid transparent;
  }
  .list-group-item {
    display: flex;
    flex-wrap: wrap;
    &.active {
      background-color: @color-pf-white;
      color: currentColor;
      z-index: auto;
      &:hover {
        border-color: @list-group-top-border;
      }
      > .list-group-item-header {
        background-color: @list-view-active-bg;
        color: @list-group-link-color;
        &:before {
          background-color: @list-view-active-bg;
        }
        &:hover {
          background-color: @list-view-hover-bg;
          &:before {
            background-color: @list-view-hover-bg;
          }
        }
        .list-group-item-text {
          color: @list-group-link-color;
        }
      }
      .list-group-item-text {
        color: currentColor;
      }
    }
    border-color: @list-group-top-border;
    padding: 0;
    background-clip: padding-box;
    .list-group-item {
      border-bottom: none;
      padding-left: @tree-list-view-pf-padding-left;
      .list-group-item-header:before {
        border-bottom: solid 1px @list-group-top-border;
        content: "";
        height: ~"calc(100% + 1px)";
        left: -100%;
        position: absolute;
        width: 100%;
      }
    }
  }
  .list-group-item-container {
    border-top-color: @list-group-top-border;
    margin: 0;
    padding: 0;
  }
  .list-group-item-header {
    box-sizing: border-box;
    margin: 0;
    position: inherit;
    &:hover {
      background-color: @list-view-hover-bg;
      border-left-color: transparent;
      border-right-color: transparent;
      &:before {
        background-color: @list-view-hover-bg;
      }
    }
  }
  .list-group-item-heading {
    @media (min-width: @screen-md-min) {
      width: ~"calc(25% - 20px)"; // IE9 fallback
    }
  }
}
