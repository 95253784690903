//
// Badges
// --------------------------------------------------

.badge {
  margin-left: 6px;
}

.nav-pills > li > a > .badge {
  margin-left: 6px;
}
