//
// Toolbar (Filter and view options)
// --------------------------------------------------

.toolbar-pf {
  background: @color-pf-white;
  border-bottom: 1px solid @sidebar-pf-border-color;
  box-shadow: 0 1px 0px fade(@color-pf-black, 4.5%);
  padding-top: (@grid-gutter-width/4);
  .form-group {
    margin-bottom: (@grid-gutter-width/4);
    @media (min-width: @grid-float-breakpoint) {
      border-right: 1px solid @sidebar-pf-border-color;
      float: left;
      margin-bottom: 0;
      padding-left: (@grid-gutter-width/2);
      padding-right: (@grid-gutter-width/2);
    }
    &:last-child {
      border-right: 0;
      margin-bottom: 0;
      padding-right: 0;
    }
    .btn,
    .btn-group {
      + .btn,
      + .btn-group { margin-left: 5px; }
      + .btn-link,
      + .dropdown { margin-left: 10px; }
    }
    .btn-link {
      color: @gray-darker;
      font-size: (@font-size-base + 4);
      line-height: 1;
      padding: 4px 0;
      &:active,
      &:focus,
      &:hover { color: @link-color; }
    }
    .dropdown-kebab-pf .btn-link {
      padding: 4px (@grid-gutter-width/4);
      margin-left: (@grid-gutter-width/(-4));
      margin-right: (@grid-gutter-width/(-4));
    }
  }
}
.toolbar-pf-actions {
  display: table;
  margin-bottom: 10px;
  width: 100%;
  @media (min-width: @grid-float-breakpoint) {
    .toolbar-pf-filter {
      padding-left: 0;
    }
  }
}
.toolbar-pf-view-selector {
  font-size: (@font-size-base + 4);
  .btn-link.active {
    color: @link-color;
    cursor: default;
  }
}
.toolbar-pf-action-right {
  float: right;
  @media (max-width: @grid-float-breakpoint) {
    float: none;
  }
}
.toolbar-pf-find {
  font-size: (@font-size-base + 2);
  position: relative;
}
.find-pf-dropdown-container {
  background: @color-pf-white;
  border: solid 1px @color-pf-black-400;
  display: none;
  right: -20px;
  padding: 5px;
  position: absolute;
  top: 35px;
  width: 300px;
  z-index: 10000;
  @media (max-width: @grid-float-breakpoint) {
    left: 30px;
    top: -5px;
    width: calc(100% - 30px);
  }
  &:before,
  &:after {
    content: "";
    position: absolute;
  }
  &:before {
    border-bottom:11px solid @color-pf-black-400;
    border-left:11px solid transparent;
    border-right:11px solid transparent;
    right: 35px;
    .toolbar-pf-find:last-child & {
      right: 15px;
    }
    top: -12px;
    @media (max-width: @grid-float-breakpoint) {
      border-bottom:11px solid transparent;
      border-right:11px solid @color-pf-black-400;
      border-top:11px solid transparent;
      left: -22px;
      right: initial;
      top: 8px;
    }
  }
  &:after {
    border-bottom:10px solid @color-pf-white;
    border-left:10px solid transparent;
    border-right:10px solid transparent;
    right: 36px;
    .toolbar-pf-find:last-child & {
      right: 16px;
    }
    top: -10px;
    @media (max-width: @grid-float-breakpoint) {
      border-bottom:10px solid transparent;
      border-right:10px solid @color-pf-white;
      border-top:10px solid transparent;
      left: -20px;
      right: initial;
      top: 9px;
    }
  }
  input {
    height: 30px;
    padding: 5px 117px 5px 5px; // 112px (components) + 5px (offset)
    width: 100%;
  }
  .find-pf-buttons {
    position: absolute;
    right: 10px;
    top: 5px;
    .btn {
      border: none;
      cursor: pointer;
      margin-left: 0 !important;
      padding: 0;
      width: 18px;
      .fa-angle-up,
      .fa-angle-down {
        font-weight: bold;
        font-size: (@font-size-base + 6);
      }
      .pficon-close { font-size: (@font-size-base + 2); }
    }
    span {
      height: 30px;
      line-height: 30px;
      vertical-align: middle;
    }
    .find-pf-nums {
      color: @color-pf-black-500;
      margin-right: 3px;
    }
  }
}

.toolbar-pf-results {
  border-top: 1px solid @sidebar-pf-border-color;
  margin-top: (@grid-gutter-width/4);
  h5,
  p,
  ul {
    display: inline-block;
    line-height: (@grid-gutter-width/1.5);
    margin-bottom: 0;
    margin-top: 0;
    @media (min-width: @screen-sm-min) {
      line-height: @grid-gutter-width;
    }
  }
  h5 {
    font-weight: 700;
    margin-right: 20px;
  }
  .label {
    font-size: (@font-size-base - 1);
    a {
      color: @color-pf-white;
      display: inline-block;
      margin-left: 5px;
    }
  }
}
