//
// Login
// --------------------------------------------------

.login-pf {
  height: 100%;
  background: @login-bg-color url("@{img-path}/@{img-bg-login}") repeat-x 50% 0;
  background-size: auto;
  @media (min-width: @screen-sm-min) {
    background-size: 100% auto;
  }
  #brand {
    position: relative;
    top: -70px;
    img {
      display: block;
      height: 18px;
      margin: 0 auto;
      max-width: 100%;
      @media (min-width: @screen-sm-min) {
        margin: 0;
        text-align: left;
      }
    }
  }
  #badge {
    display: block;
    margin: 20px auto 70px;
    position: relative;
    text-align: center;
    @media (min-width: @screen-sm-min) {
      float: right;
      margin-right: 64px;
      margin-top: 50px;
    }
  }
  body {
    background: @login-bg-color url("@{img-path}/@{img-bg-login}") repeat-x 50% 0;
    background-size: auto;
    @media (min-width: @screen-sm-min) {
      background-size: 100% auto;
    }
  }
  .container {
    background-color: @login-container-bg-color-rgba;
    clear: right;
    color: @color-pf-white;
    padding-bottom: 40px;
    padding-top: 20px;
    width: auto;
    @media (min-width: @screen-sm-min) {
      bottom: 13%;
      padding-left: 80px;
      position: absolute;
      width: 100%;
    }
    .details {
      p:first-child {
        border-top: 1px solid fade(@color-pf-white, 30%);
        padding-top: 25px;
        margin-top: 25px;
      }
      @media (min-width: @screen-sm-min) {
        p:first-child {
          border-top: 0;
          padding-top: 0;
          margin-top: 0;
        }
        border-left: 1px solid fade(@color-pf-white, 30%);
        padding-left: 40px;
      }
      p {
        margin-bottom: 2px;
      }
    }
    .form-horizontal {
      .control-label {
        font-size: (@font-size-base + 1);
        font-weight: 400;
        text-align: left;
      }
      .form-group:last-child {
        &,
        .help-block:last-child {
          margin-bottom: 0;
        }
      }
    }
    .help-block {
      color: @color-pf-white;
    }
    .login {
      @media (min-width: @screen-sm-min) {
        padding-right: 40px;
      }
    }
    .submit {
      text-align: right;
    }
  }
}

//
// Login
// The following styles are for the new login
// --------------------------------------------------
.login-pf-page {
  padding-top: @login-pf-page-padding-top;

  .login-pf-brand {
    margin-top: @login-pf-brand-margin-top;
    max-width:360px;
    width: 70%;
    @media (min-width: @screen-sm-min) {
      margin-top: @login-pf-brand-desktop-margin-top;
    }
  }
  .login-pf-page-header {
    margin-bottom: @login-pf-page-header-margin-bottom;
      @media (min-width: @screen-sm-min) {
        margin-bottom: @login-pf-page-header-desktop-margin-bottom;
      }
    text-align: center;
    p {
      color: @color-pf-white;
      font-size: @font-size-large;
      margin-left: auto;
      margin-right: auto;
      margin-top: @login-pf-page-header-paragraph-margin-top;
    }
  }
  .card-pf {
    padding: @login-pf-card-pf-padding;
    margin-bottom: 0;
    @media (min-width: @screen-sm-min) {
      padding: @login-pf-card-pf-desktop-padding;
    }
    p {
      color: @color-pf-black-600;
    }
  }
  .form-control {
    height: ceil((@input-height-base + 10px ));
  }
  .checkbox-label {
    color: @color-pf-black-600;
    font-weight: 300;
    margin-bottom: @login-pf-checkbox-label-margin-bottom;
    @media (min-width: @screen-sm-min) {
      margin: 0;
    }
  }
  .btn-primary {
    margin-top: @login-pf-btn-primary-margin-top;
  }
  .login-pf-header {
    margin-bottom: @login-pf-header-margin-bottom;
    @media (min-width: @screen-sm-min) {
      margin-bottom:@login-pf-header-desktop-margin-bottom;
    }
    display:flex;
    flex-direction: column;
    h1 {
      text-align: center;
    }
    h1 {
      font-size: @font-size-h3;
      @media (min-width: @screen-sm-min) {
        font-size: @font-size-h1;
      }
    }
    //this is ugly but neccessary to get the desired style :(
    .bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
      width: auto;
      align-self: flex-end;
      .dropdown-toggle {
        color: @color-pf-black-700;
        background: none;
        padding:0 @login-pf-dropdown-toggle-padding-right 0 0;
        font-weight: 300;
        &:not(:focus) {
          box-shadow: none;
          border: 1px solid transparent;
        }
          .caret {
            right: 0;
          }
      }
    }
  }

  .login-pf-signup {
    margin: @login-pf-signup-margin-top 0 0;
    font-size: ceil((@font-size-base * 1.25));
    text-align: center;
    a {
      margin-left: @login-pf-signup-a-margin-left;
    }
  }
  .login-pf-settings {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .login-pf-page-footer {
    display: flex;
    justify-content: center;
    flex-wrap:wrap;
    padding-bottom: @login-pf-page-footer-padding-bottom-mobile;
      @media (min-width: @screen-sm-min) {
        padding-bottom: @login-pf-page-footer-padding-bottom-desktop;
      }
    &-links {
      display: flex;
      margin: @login-pf-page-footer-links-margin-top 0 0 0;
        li:not(:last-of-type) {
          position: relative;
          margin: 0 @login-pf-page-footer-links-li-margin-right 0 0;
            &:after {
              content:".";
              color: @color-pf-white;
              position: absolute;
              top: 0px;
              right: -10px;
            }
        }
    }
    &-link {
      color: @color-pf-white;
      font-size: @font-size-large;
    }
    &-sso-services {
      flex: 1 0 100%;
      margin-top: @login-pf-page-footer-sso-margin-top;
      text-align: center;
      color: @color-pf-white;
      &-logos {
        display: flex;
        flex-wrap: wrap;
        padding: 0;
        margin: 0;
        list-style: none;
        justify-content: center;
          li {
            margin: 0 @login-pf-page-footer-sso-logos-li-margin @login-pf-page-footer-sso-logos-li-margin;
          }
      }
    }
  }//login-pf-footer


  // The following styles are for the login page with different account options
  // --------------------------------------------------------------------------

  &.login-pf-page-accounts {
    margin-left: @login-pf-page-accounts-margin;
    margin-right: @login-pf-page-accounts-margin;
  }
  .login-pf-accounts {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .login-pf-header, .login-pf-signup {
      flex-basis: 100%;
    }
    max-width: @login-pf-card-pf-max-width;
    margin-left: auto;
    margin-right: auto;
  }
  .login-pf-social-section {
    flex-basis: 100%;
    padding:0;
    @media (min-width: @screen-sm-min) {
    flex:0 1 50%;
    max-width:50%;// this is for IE10/11 that doesn't consider padding with box-sizing on flex-children.
    }
    @media (min-width: @screen-sm-min) {
      &:first-of-type {
        padding-right: @login-pf-accounts-section-heading-desktop-padding-right;
      }
      &:last-of-type {
        padding-left: ceil((@login-pf-accounts-section-heading-desktop-padding-left - 1px));
        border-left: 1px solid @color-pf-black-300;
      }
    }
  }//social-section
  .login-pf-social {
    margin: @login-pf-social-margin;
    @media (min-width: @screen-sm-min) {
      margin: 0;
      &-double-col {
        flex-wrap: wrap;
        display: flex;
        justify-content: space-between;
        .login-pf-social-link {
          flex-basis: 48%;
        }
      }
    }

    &:not(.login-pf-social-all) {
      .login-pf-social-link-more {
        display: none;
      }
    }

    &-link {
        margin-bottom: @login-pf-accounts-link-margin-bottom;
      a {
        display: block;
        padding: @login-pf-accounts-link-a-padding 0;
        font-size: @font-size-large;
        background: @color-pf-black-150;
        text-align: center;
        color: @color-pf-black-600;
          img {
            margin: 0 @login-pf-accounts-link-img-margin-right 0 0;
            width: 20px;
            height: 20px;
          }
          &:hover {
            text-decoration: none;
            background: @color-pf-black-200;
          }
      }
    }
    &-toggle {
      float: right;
      padding: 0;
      .caret {
        margin-left: @login-pf-accounts-more-caret-margin-left;
      }
      &-active {
        .caret {
          transform: rotate(-180deg);
        }
      }
    }
  }

}//login-pf-page
