.jstree-@{theme-name} {
	.jstree-node,
	.jstree-icon { background-repeat:no-repeat; background-color:transparent; }
	.jstree-anchor,
	.jstree-animated,
	.jstree-wholerow { transition:background-color 0.15s, box-shadow 0.15s; }
	.jstree-hovered { background:@hovered-bg-color; border-radius:2px; box-shadow:inset 0 0 1px @hovered-shadow-color; }
	.jstree-context { background:@hovered-bg-color; border-radius:2px; box-shadow:inset 0 0 1px @hovered-shadow-color; }
	.jstree-clicked { background:@clicked-bg-color; border-radius:2px; box-shadow:inset 0 0 1px @clicked-shadow-color; }
	.jstree-no-icons .jstree-anchor > .jstree-themeicon { display:none; }
	.jstree-disabled {
		background:transparent; color:@disabled-color;
		&.jstree-hovered { background:transparent; box-shadow:none; }
		&.jstree-clicked { background:@disabled-bg-color; }
		> .jstree-icon { opacity:0.8; filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'jstree-grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#jstree-grayscale"); /* Firefox 10+ */ filter: gray; /* IE6-9 */ -webkit-filter: grayscale(100%); /* Chrome 19+ & Safari 6+ */ }
	}
	// search
	.jstree-search { font-style:italic; color:@search-result-color; font-weight:bold; }
	// checkboxes
	.jstree-no-checkboxes .jstree-checkbox { display:none !important; }
	&.jstree-checkbox-no-clicked {
		.jstree-clicked {
			background:transparent;
			box-shadow:none;
			&.jstree-hovered { background:@hovered-bg-color; }
		}
		> .jstree-wholerow-ul .jstree-wholerow-clicked {
			background:transparent;
			&.jstree-wholerow-hovered { background:@hovered-bg-color; }
		}
	}
	// stripes
	> .jstree-striped { min-width:100%; display:inline-block; background:url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAAkCAMAAAB/qqA+AAAABlBMVEUAAAAAAAClZ7nPAAAAAnRSTlMNAMM9s3UAAAAXSURBVHjajcEBAQAAAIKg/H/aCQZ70AUBjAATb6YPDgAAAABJRU5ErkJggg==") left top repeat; }
	// wholerow
	> .jstree-wholerow-ul .jstree-hovered,
	> .jstree-wholerow-ul .jstree-clicked { background:transparent; box-shadow:none; border-radius:0; }
	.jstree-wholerow { -moz-box-sizing:border-box; -webkit-box-sizing:border-box; box-sizing:border-box; }
	.jstree-wholerow-hovered { background:@hovered-bg-color; }
	.jstree-wholerow-clicked { .gradient(@clicked-gradient-color-1, @clicked-gradient-color-2); }
}

// theme variants
.jstree-@{theme-name} {
	.jstree-theme(24px, "@{image-path}32px.png", 32px);
	&.jstree-rtl .jstree-node { background-image:url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAACAQMAAAB49I5GAAAABlBMVEUAAAAdHRvEkCwcAAAAAXRSTlMAQObYZgAAAAxJREFUCNdjAAMOBgAAGAAJMwQHdQAAAABJRU5ErkJggg=="); }
	&.jstree-rtl .jstree-last { background:transparent; }
}
.jstree-@{theme-name}-small {
	.jstree-theme(18px, "@{image-path}32px.png", 32px);
	&.jstree-rtl .jstree-node { background-image:url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAACAQMAAABv1h6PAAAABlBMVEUAAAAdHRvEkCwcAAAAAXRSTlMAQObYZgAAAAxJREFUCNdjAAMHBgAAiABBI4gz9AAAAABJRU5ErkJggg=="); }
	&.jstree-rtl .jstree-last { background:transparent; }
}
.jstree-@{theme-name}-large {
	.jstree-theme(32px, "@{image-path}32px.png", 32px);
	&.jstree-rtl .jstree-node { background-image:url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAACAQMAAAAD0EyKAAAABlBMVEUAAAAdHRvEkCwcAAAAAXRSTlMAQObYZgAAAAxJREFUCNdjgIIGBgABCgCBvVLXcAAAAABJRU5ErkJggg=="); }
	&.jstree-rtl .jstree-last { background:transparent; }
}

// mobile theme attempt
@media (max-width: 768px) {
	#jstree-dnd.jstree-dnd-responsive when (@responsive = true) {
		line-height:@base-height; font-weight:bold; font-size:1.1em; text-shadow:1px 1px white;
		> i { background:transparent; width:@base-height; height:@base-height; }
		> .jstree-ok { background-image:url("@{image-path}@{base-height}.png"); background-position:0 -(@base-height * 5); background-size:(@base-height * 3) (@base-height * 6); }
		> .jstree-er { background-image:url("@{image-path}@{base-height}.png"); background-position:-(@base-height * 1) -(@base-height * 5); background-size:(@base-height * 3) (@base-height * 6); }
	}
	#jstree-marker.jstree-dnd-responsive when (@responsive = true) {
		border-left-width:10px;
		border-top-width:10px;
		border-bottom-width:10px;
		margin-top:-10px;
	}
}

.jstree-@{theme-name}-responsive when (@responsive = true) {
	@import "responsive.less";
}
