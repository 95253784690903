//
// Date and Time
// --------------------------------------------------
.date-time-picker-pf .input-group-addon {
  .fa, .fas, .far, .fab, .fal,
  .pficon {
    width: 12px;
  }
}

.date-time-range-picker-pf {
  .form-control,
  .form-control:not(:first-child):not(:last-child) {
    border-radius: @border-radius-base;
  }
  .form-control:first-child {
    border-bottom-right-radius: @border-radius-base;
    border-top-right-radius: @border-radius-base;
  }
  .form-control:last-child {
    border-bottom-left-radius: @border-radius-base;
    border-top-left-radius: @border-radius-base;
  }
  .input-group-addon {
    background-color: transparent;
    border: none;
    line-height: @line-height-base;
    padding: @padding-base-vertical @padding-base-horizontal;
  }
  .dropdown-kebab-pf {
    float: right;
    margin-left: 0;
    margin-right: -15px;
  }
}
