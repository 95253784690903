//
// Dropdowns
// --------------------------------------------------


// Dropdown arrow/caret
// Modified to use Font Awesome's angle-down icon
.caret {
  font-family: @icon-font-name-fa;
  font-weight: normal;
  height: (@font-size-base - 3);
  position: relative;
  vertical-align: baseline;
  width: @font-size-base;
  &:before {
    bottom: 0;
    content: @fa-var-angle-down;
    left: 0;
    line-height: @font-size-base;
    position: absolute;
    text-align: center;
    top: -1px;
    right: 0;
  }
  .dropup & {
    &:before {
      content: @fa-var-angle-up;
    }
  }
}

// Bootstrap removes the focus ring on dropdowns; this replaces it for better accessibility
.dropdown-toggle:focus {
  .tab-focus();
}

// The dropdown menu (ul)
.dropdown-menu {
  // Dividers (basically an hr) within the dropdown
  .divider {
    .nav-divider(@dropdown-divider-bg, @dropdown-divider-margin);
  }
  // Links within the dropdown menu
  > li > a {
    border-color: transparent;
    border-style: solid;
    border-width: 1px 0;
    padding: 1px 10px;
  }
}

// Hover/Focus state
.dropdown-menu > li > a {
  &:hover,
  &:focus {
    border-color: @dropdown-link-hover-border-color;
    .reset-filter();
  }
  &:active {
    background-color: @dropdown-link-focus-bg;
    border-color: @dropdown-link-active-border-color;
    color: @dropdown-link-focus-color !important;
    .reset-filter();
  }
}

// Active state
.dropdown-menu > .active > a {
  &,
  &:hover,
  &:focus {
    background-color: @dropdown-link-active-bg !important;
    border-color: @dropdown-link-active-border-color !important;
    .reset-filter();
  }
}

// Disabled state
.dropdown-menu > .disabled > a {
  &,
  &:hover,
  &:focus {
    border-color: transparent;
  }
}

// Selected state
.dropdown-menu > .selected > a {
  background-color: @dropdown-link-active-bg;
  border-color: @dropdown-link-active-border-color;
  color: @color-pf-white;
  small {
    color: fade(@color-pf-white, 50%);
  }
}

// Nuke hover/focus effects
.dropdown-menu > .disabled > a {
  &:hover,
  &:focus {
    border-color: transparent;
  }
}

// Dropdown section headers
.dropdown-header {
  padding-left: 10px;
  padding-right: 10px;
  text-transform: uppercase;
}

// Position Menu closer to button
.btn-group,
.dropdown,
.input-group-btn {
  > .dropdown-menu {
    margin-top: -1px;
  }
}

// Position Menu closer to button (dropup-menu)
.dropup .dropdown-menu {
  margin-bottom: -1px;
}

// Add back styles for dropdown-submenu
.dropdown-submenu {
  position:relative;
  &:hover {
    > a {
      background-color: @dropdown-link-hover-bg;
      border-color: @dropdown-link-hover-border-color;
    }
    > .dropdown-menu {
      display: block;
    }
  }
  &.pull-left {
    float: none !important;
    > .dropdown-menu {
      left: auto;
      margin-left: 10px;
      right: 100%;
    }
  }
  > a {
    padding-right: 20px !important;
    &:after {
      content: @fa-var-angle-right;
      font-family: @icon-font-name-fa;
      display: block;
      position: absolute;
      right: 10px;
      top: 2px;
    }
  }
  > .dropdown-menu {
    left: 100%;
    margin-top: 0;
    top: -6px;
  }
  .dropup & > .dropdown-menu {
    bottom: -5px;
    top: auto;
  }
  .open &.active > .dropdown-menu {
    display: block;
  }
}

// Kebab dropmenu
.dropdown-kebab-pf {
  &.btn-group > .btn:first-child,
  .btn-link {
    color: @gray-darker;
    font-size: (@font-size-base + 4);
    line-height: 1;
    padding: 4px (@grid-gutter-width/4);
    margin-left: (@grid-gutter-width/(-4));
    margin-right: (@grid-gutter-width/(-4));
    &:active,
    &:focus,
    &:hover {
      color: @link-color;
    }
  }
  &.btn-group { margin-left: (@grid-gutter-width/4); }
  .dropdown-menu {
    left: -15px;
    margin-top: 11px;
    &.dropdown-menu-right {
      left: auto;
      right: -15px;
      &:after,
      &:before {
        left: auto;
        right: 6px;
      }
    }
    &:after,
    &:before {
      border-bottom-color: @dropdown-border;
      border-bottom-style: solid;
      border-bottom-width: 10px;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      content: "";
      display: inline-block;
      left: 6px;
      position: absolute;
      top: -11px;
    }
    &:after {
      border-bottom-color: @dropdown-bg;
      top: -10px;
    }
  }
  &.dropup .dropdown-menu {
    margin-bottom: 11px;
    margin-top: 0;
    &:after,
    &:before {
      border-bottom: none;
      border-top-color: @dropdown-border;
      border-top-style: solid;
      border-top-width: 10px;
      bottom: -11px;
      top: auto;
    }
    &:after {
      border-top-color: @dropdown-bg;
      bottom: -10px;
    }
  }
}
