//
// List groups
// --------------------------------------------------

.list-group {
  border-top: 1px solid @list-group-top-border;
  .list-group-item:first-child {
    border-top: 0;
  }
}
.list-group-item {
  border-left: 0;
  border-right: 0;
  &.disabled,
  &.disabled:hover,
  &.disabled:focus {
    background-color: @list-group-disabled-bg;
  }
}

.list-group-item-heading {
  font-weight: 600;
}
