//
// Cards
// --------------------------------------------------

.card-pf {
  background: @card-pf-bg-color;
  border-top: 2px solid @card-pf-border-top-color;
  .box-shadow(0 1px 1px fade(@color-pf-black, 17.5%));
  margin: 0 (-(@grid-gutter-width / 4)) (@grid-gutter-width / 2);
  padding: 0 (@grid-gutter-width / 2);
  &.card-pf-accented {
    border-top-color: @card-pf-accented-border-top-color;
  }
  &.card-pf-aggregate-status {
    .card-pf-aggregate-status-notifications,
    .card-pf-title {
      a {
        color: @text-color;
        &.add {
          color: @link-color;
          &:hover {
            color: @link-hover-color;
          }
        }
        &:hover {
          color: @link-hover-color;
        }
      }
    }
  }
  &.card-pf-aggregate-status {
    padding: 0 (@grid-gutter-width / 4);
    text-align: center;
  }
  &.card-pf-aggregate-status-mini {
    padding-bottom: (@grid-gutter-width / 4);
    position: relative;
  }
  @media (min-width: @grid-float-breakpoint) {
    &.card-pf-bleed-left {
      margin-left: (-(@grid-gutter-width / 2));
    }
    &.card-pf-bleed-right {
      border-right: 1px solid @card-pf-border-color;
      margin-right: (-(@grid-gutter-width / 2));
    }
  }
}

.card-pf-aggregate-status-notifications {
  font-size: (@font-size-base * 2); // 24px
  font-weight: 300;
  .card-pf-aggregate-status-mini & {
    line-height: 1;
  }
  .card-pf-aggregate-status-notification + .card-pf-aggregate-status-notification {
    border-left: 1px solid @card-pf-border-color;
    margin-left: (@grid-gutter-width / 8 - 2);
    padding-left: (@grid-gutter-width / 4);
  }
  .fa, .fas, .far, .fab, .fal, .pficon {
    font-size: (@font-size-base * 1.5); // 18px
    margin-right: 7px;
  }
}

.card-pf-body {
  margin: (@grid-gutter-width / 2) 0 0;
  padding: 0 0 (@grid-gutter-width / 2);
  .card-pf-aggregate-status & {
    margin-top: (@grid-gutter-width / 4);
    padding-bottom: (@grid-gutter-width / 4);
  }
  .card-pf-aggregate-status-mini & {
    margin-bottom: 0;
    margin-top: 0;
    padding-bottom: 0;
    position: absolute;
    right: (@grid-gutter-width / 2);
    top: 15px;
  }
  .card-pf-utilization .card-pf-title + & {
    margin-top: -8px;
  }
  > *:last-child {
    margin-bottom: 0;
  }
}

.card-pf-footer {
  background-color: @card-pf-footer-bg-color;
  border-top: 1px solid @card-pf-border-color;
  margin: 0 (-(@grid-gutter-width / 2)) !important;
  padding: (@grid-gutter-width / 2) (@grid-gutter-width / 2) (@grid-gutter-width / 4);
  a > {
    .fa, .fas, .far, .fab, .fal,
    .pficon {
      margin-right: 5px;
    }
  }
  .card-pf-time-frame-filter {
    margin-top: -2px;
  }
}

.card-pf-link-with-icon {
  padding-left: 21px;
  position: relative;
  .fa, .fas, .far, .fab, .fal,
  .pficon {
    font-size: 16px;
    left: 0;
    position: absolute;
    top: 0;
  }
}

.card-pf-time-frame-filter {
  .card-pf-heading &,
  .card-pf-footer & {
    float: right;
    margin-left: 20px;
  }
}

.card-pf-heading {
  border-bottom: 1px solid @card-pf-border-color;
  margin: 0 (-(@grid-gutter-width / 2)) (@grid-gutter-width / 2);
  padding: 0 (@grid-gutter-width / 2) 0;
  & .card-pf-time-frame-filter {
    margin-top: -5px;
  }
}

.card-pf-heading-details {
  float: right;
  font-size: (@font-size-small - 1);
}

.card-pf-subtitle {
  font-size: @font-size-h3;
  margin-top: @line-height-computed;
  margin-bottom: (@line-height-computed / 2);
  [class^="col"] & {
    margin-top: 0;
  }
  @media (max-width: @screen-xs-max) {
    .card-pf-body [class^="col"] + [class^="col"] > & {
      margin-top: (@grid-gutter-width);
    }
  }
}

.card-pf-title {
  font-size: @font-size-h3;
  font-weight: 400;
  margin: (@grid-gutter-width / 2) 0;
  padding: 0;
  .card-pf-aggregate-status & {
    font-size: @font-size-large;
    margin: (@grid-gutter-width / 4) 0 0;
    .fa, .fas, .far, .fab, .fal,
    .pficon {
      color: @card-pf-aggregate-status-title-icon-color;
      font-size: @font-size-h3;
      margin-right: 7px;
    }
  }
  .card-pf-aggregate-status-count {
    font-size: @font-size-h3;
    .card-pf-aggregate-status-mini & {
      display: block;
      font-size: (@font-size-base * 2); // 24px
      font-weight: 300;
      margin-bottom: 3px;
    }
  }
  .card-pf-aggregate-status-mini & {
    font-size: @font-size-base;
    margin-top: (@grid-gutter-width / 8);
    a {
      display: inline-block;
    }
    .fa, .fas, .far, .fab, .fal,
    .pficon {
      font-size: (@font-size-base * 2 + 2); // 26px
      margin-right: 0;
      min-width: (@font-size-base * 2 + 2); // 26px
      position: absolute;
      left: (@grid-gutter-width / 2);
      text-align: center;
      top: 15px;
    }
  }
}

.card-pf-utilization-details {
  border-bottom: 1px solid @card-pf-border-color;
  display: table;
  margin: 12px 0 15px;
  padding: 0 0 15px;
  width: 100%;
  .card-pf-utilization-card-details-count,
  .card-pf-utilization-card-details-description {
    float: left;
    line-height: 1;
  }
  .card-pf-utilization-card-details-count {
    font-size: (@font-size-base * 2 + 2); // 26px
    font-weight: 300;
    margin-right: 10px;
  }
  .card-pf-utilization-card-details-line-1,
  .card-pf-utilization-card-details-line-2 {
    display: block;
  }
  .card-pf-utilization-card-details-line-1 {
    font-size: (@font-size-small - 1);
    margin-bottom: 2px;
  }
}

.cards-pf {
  background: @card-pf-container-bg-color;
  .row-cards-pf {
    padding: 0 20px;
    &:first-child { padding-top: 20px; }
  }
}

.container-cards-pf {
  margin-top: (@grid-gutter-width / 2);
}

.row-cards-pf {
  margin-left: (-(@grid-gutter-width / 4));
  margin-right: (-(@grid-gutter-width / 4));
}
