/*
 *  Copyright 2022 Red Hat
 *
 *  Licensed under the Apache License, Version 2.0 (the "License");
 *  you may not use this file except in compliance with the License.
 *  You may obtain a copy of the License at
 *
 *      https://www.apache.org/licenses/LICENSE-2.0
 *
 *  Unless required by applicable law or agreed to in writing, software
 *  distributed under the License is distributed on an "AS IS" BASIS,
 *  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 *  See the License for the specific language governing permissions and
 *  limitations under the License.
 */
.table-hover {
  tbody td.dataTables_empty, tbody td.dataTables_empty:hover {
    background-color: #fff;
  }
}

.datatable {
  .focus {
    outline: 1px dashed #666;
  }

  .selected .focus {
    outline: none;
  }

  td.select-checkbox {
    position: relative;
  }
  td.select-checkbox:before {
    content: '\f096';
    font-family: FontAwesome;
    font-size: 14px;
    left: 14px;
    position: absolute;
    top: 2px;
  }
  tr.selected td.select-checkbox:before {
    content: '\f046';
  }
}

.table {
  margin-bottom: 0;
}

.column-action {
  cursor: pointer;
}

table.dataTable {
  tbody {
    > tr {
      > td {
        &.table-view-hal-actions {
          padding: 0;
          vertical-align: middle;
        }
      }
      &:hover {
        > td.table-view-hal-actions {
          background-color: @table-bg-accent;
          border-bottom-color: @table-border-color;
        }
      }
    }
    > tr.selected {
      > td {
        &.table-view-hal-actions {
          background-color: @table-bg-accent;
          border-bottom-color: @table-border-color;
        }
      }
      &:hover {
        > td {
          &.table-view-hal-actions {
            background-color: @table-bg-accent;
            border-bottom-color: @table-border-color;
          }
        }
      }
    }
  }
}

.table-view-hal-actions {
  background-color: @btn-default-bg;
  height: 100%;

  .table-view-hal-btn {
    display: flex;
  }

  .btn {
    border-left: none;
    border-top: none;
    border-right: 1px solid #d1d1d1;
    border-bottom: none;
    box-shadow: none;
    flex: 1;
    height: 100%;
    .text-overflow();
    width: 0;

    &:last-child {
      border-right: none;
    }
  }
}