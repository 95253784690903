/*
 *  Copyright 2022 Red Hat
 *
 *  Licensed under the Apache License, Version 2.0 (the "License");
 *  you may not use this file except in compliance with the License.
 *  You may obtain a copy of the License at
 *
 *      https://www.apache.org/licenses/LICENSE-2.0
 *
 *  Unless required by applicable law or agreed to in writing, software
 *  distributed under the License is distributed on an "AS IS" BASIS,
 *  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 *  See the License for the specific language governing permissions and
 *  limitations under the License.
 */
.model-browser-header, .model-browser-buttons, .model-browser-content {
  margin-top: @margin-large;
}

.model-browser-buttons {
  .tag-manager-container {
    display: inline;
    margin-left: 10px;
  }

  .tag-manager-tag {
    border-radius: 0;
    margin-top: 0;
  }
}

.model-browser-content {
  overflow-x: hidden;
  overflow-y: scroll;

  .lead {
    font-size: 14px;
  }

  h1 {
    font-size: 16px;
    margin-top: 0;
  }

  #model-browser-children-table {
    margin-bottom: 0;
  }

  .table.attributes, .table.operations {
    margin-bottom: 0;
    margin-top: @margin-large;
    > tbody > tr > td {
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }

  .table.attributes {
    .deprecated {
      color: @color-pf-black-500;
      cursor: help;
      text-decoration: line-through;
    }
  }

  ul.operation-parameter {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      margin-bottom: 5px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
